import React from "react";
import { Container, FormLabel } from "@mui/material";
import { Box } from "@chakra-ui/react";
import DotsLoader from "common/DotsLoader";

export default function NoMatchingRecords(props) {
    return (
        <Container>
            <Box w="full">
                <FormLabel
                    sx={{ width: '100%', textAlign: 'center', fontSize: '1.25rem', color: 'red' }}
                >{`Sorry, no ${props.type} yet.`}</FormLabel>
            </Box>
        </Container>
    );
}

export function LoadingAndIcon(props) {
    return (
        <>
         {props.loading ?
        <DotsLoader height={10} width={40} float="left" message=" " /> : props.icon}
        </>
    );
}