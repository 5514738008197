import { Button, Flex } from '@chakra-ui/react';
import React, { Component } from 'react';
import './error-boundary.css';

interface Props {
	children: React.ReactNode;
}
interface State {
	hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// console.log('error', error);
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			return (
				<Flex justify="center" align="center" height="100vh">
					<div>
						<h1 style={{ fontSize: '1.8em' }}>Something went wrong</h1>
						<div className="d-flex">
							<Button variant="solid" className="error-boundary-btn" onClick={() => window.location.reload()}>
								Reload
							</Button>
						</div>
					</div>
				</Flex>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
