import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import { HubLink } from "./types";

import { NestedMenuItem } from "mui-nested-menu";
import { userCanViewSalesReports } from "./user-utils";
import { UserDetails } from "store/interfaces";

interface Props {
  title: string;
  items?: HubLink[];
  path?: string;
  inMobile?: boolean;
  isActive: (link: string) => boolean;
  userDetails: UserDetails;
}

const MenuItems = (props: Props) => {
  const {
    title,
    path,
    items = [],
    isActive,
    inMobile = false,
    userDetails,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLink = (label: string, path?: string) => {
    return path ?? `/${label.toLowerCase().replace(/ /g, "-")}`;
  };

  const showLinkItem = (item: HubLink) => {
    const { label, icon: Icon, path, target, onClick } = item;

    const link = getLink(label, path);

    if (link === "/sales/reports" && !userCanViewSalesReports(userDetails)) {
      return null;
    }

    return (
      <MenuItem
        onClick={handleClose}
        selected={isActive(link)}
        sx={{ my: "auto" }}
      >
        {Icon && <Icon />}

        <Box
          sx={{
            mx: { xs: 1, md: 1, fontSize: inMobile ? "1em" : ".9em" },
            width: "100%",
          }}
        >
          <Link
            color="primary"
            underline="hover"
            fontFamily="Montserrat-Regular"
            href={link}
            target={target}
            onClick={onClick}
            sx={{
              width: "100%",
              display: "block",
            }}
          >
            {label}
          </Link>
        </Box>
      </MenuItem>
    );
  };

  const showNestedMenu = ({ icon: Icon, items = [], label }: HubLink) => {
    const nestedMenus = items.map(showLinkItem).filter((v) => v !== null);

    if (nestedMenus.length === 0) return null;

    return (
      <NestedMenuItem
        sx={{
          pl: { xs: 2, md: 2 },
          fontFamily: "Montserrat-Regular",
        }}
        className="nav-menu"
        MenuProps={{
          className: "nav-menu",
        }}
        leftIcon={
          Icon && (
            <Box my="auto">
              <Icon />
            </Box>
          )
        }
        rightIcon={<ChevronRightIcon />}
        label={label}
        parentMenuOpen={open}
      >
        {nestedMenus}
      </NestedMenuItem>
    );
  };

  return (
    <Box className=".nav-menu" sx={{ my: "auto" }}>
      {items.length > 0 ? (
        <Button
          {...(!inMobile ? { variant: "contained" } : {})}
          onClick={handleClick}
          onMouseEnter={handleClick}
          endIcon={items.length > 0 && <KeyboardArrowDownIcon />}
          disableElevation
          sx={{
            mb: inMobile ? 0.1 : 0,
            fontFamily: "Montserrat-Regular",
            textTransform: "capitalize",
          }}
        >
          {title}
        </Button>
      ) : (
        <Box sx={{ mx: { xs: 1, md: 1 }, fontFamily: "Montserrat-Regular" }}>
          <Link
            color={inMobile ? "primary" : "white"}
            sx={{ "&:hover": { color: inMobile ? "primary" : "white" } }}
            underline="hover"
            href={getLink(title, path)}
          >
            {title}
          </Link>
        </Box>
      )}

      {items.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className="nav-menu"
          PaperProps={{
            onMouseLeave: handleClose,
            sx: {
              fontFamily: "Montserrat-Regular",
            },
          }}
        >
          {items.map((item) => {
            if (item.items) {
              return showNestedMenu(item);
            }

            return showLinkItem(item);
          })}
        </Menu>
      )}
    </Box>
  );
};

export default MenuItems;
