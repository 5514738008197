import {lazy} from 'react';
import { RouteObject } from "./interfaces";

const ITSupportRequests = lazy(() => import('components/common/it-requests/it-support-requests'));
const ITBusinessRequests = lazy(() => import('components/common/it-requests/it-business-requests'));
const ITCalendar = lazy(() => import('components/common/it-requests/calendar'));

const IT_REQUESTS: RouteObject[] = [
    {path: '/it/support-requests', element: ITSupportRequests},
    {path: '/it/business-requests', element: ITBusinessRequests},
    {path: '/it/development-calendar', element: ITCalendar}
];

export default IT_REQUESTS;