import { extendTheme, withDefaultColorScheme, withDefaultSize } from '@chakra-ui/react';
import colorPallete from './color-palette';

const themeConfig = {
	colors: colorPallete,
	fonts: {
		heading: "Montserrat,'Work Sans', Arial, Helvetica, sans-serif",
		body: "Montserrat,'Work Sans', Arial, Helvetica, sans-serif",
	},
};

export const appTheme = extendTheme(
	themeConfig,
	withDefaultColorScheme({
		colorScheme: 'primary',
		components: ['Button', 'Table', 'Heading'],
	}),
	withDefaultColorScheme({
		colorScheme: 'secondary',
		components: ['Badge', 'Avatar'],
	}),
	withDefaultSize({ size: 'sm' })
);
