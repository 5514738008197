import { LinkItem } from "../types";

function getOamsLinks(): LinkItem[] {
  return [
    { title: "Home", path: "oams" },
    { title: "Network Map", path: "oams/map" },
    // {
    // 	title: 'Map',
    // 	items: [{ label: 'Network Map', icon: FolderCheck, path: '/oams/map' }],
    // },
  ];
}
export default getOamsLinks;
