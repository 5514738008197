import { useEffect, useRef, useState } from 'react';
import { fetchJsonFromApiJsonResponse, postDataToApiJsonResponse } from 'common/FetchFunctions';
import { HttpPayload, HttpResponse } from 'components/Util/http-util';
// import { QueryOptions } from '@tanstack/react-query';

interface Props {
	endpoint: string;
	dataKey: string;
	dataLabel: string;
	dataPayload?: HttpPayload;
	forceDataSetting?: boolean;
	onHttpCallComplete?: Function;
	makeHttpCall?: boolean; //flag to use to prevent or enable making http call to the given endpoint,set to true by default

	url?: string;
	asBlob?: boolean;
	// options?: QueryOptions;
	queryKey?: readonly string[];
}

export interface RemoteDataResponse<T> {
	loading?: boolean;
	error?: string | null;
	data?: T;
	success?: boolean;
	endpoint?: string | null;
}

export const useRemoteHubServices = <T>(props: Props) => {
	if (!props.endpoint?.startsWith('http'))
		props.endpoint = `${process.env.REACT_APP_HUB_SERVICES_API_URL}/api/${props.endpoint}`;
	
	return useRemoteData<T>(props);
};

export function useRemoteData<T>(props: Props): RemoteDataResponse<T> {
	const { endpoint, dataKey, dataLabel, dataPayload, forceDataSetting = true, makeHttpCall = true, onHttpCallComplete } = props;

	const loadRef = useRef<boolean>(false);
	const [loadInfo, setLoadInfo] = useState<RemoteDataResponse<T>>({ loading: makeHttpCall });

	useEffect(() => {
		let isMounted = true;

		if (!makeHttpCall) return;

		if (loadInfo.error || loadRef.current) {
			if (loadInfo.endpoint === endpoint) return;
		}

		if (!endpoint || !dataKey) return;

		//check if we are calling the same endpoint for data,
		//if the data exists locally, then don't fetch it
		if (loadInfo.data && loadInfo.endpoint === endpoint) return;

		if (endpoint.includes('undefined') || endpoint.includes('/null') || endpoint.includes('NaN')) {
			return; //setLoadInfo({ loading: false, error: `Invalid endpoint` });
		}

		setLoadInfo({ loading: true, error: null, endpoint });
		loadRef.current = true;

		(dataPayload
			? postDataToApiJsonResponse<HttpResponse<T>>(endpoint, dataPayload)
			: fetchJsonFromApiJsonResponse<HttpResponse<T>>(endpoint)
		)
			.then(data => {
				if (!isMounted && !forceDataSetting) return;
				onHttpCallComplete?.();
				setLoadInfo({
					data: (Array.isArray(data) ? data : data[dataKey]) as T,
					loading: false,
					error: data.error ?? null,
					endpoint,
				});
			})
			.catch(e => {
				if (!isMounted && !forceDataSetting) return;
				onHttpCallComplete?.();
				console.log('error', e);
				setLoadInfo({
					loading: false,
					error: `An error occurred while loading ${dataLabel ?? dataKey}`,
					endpoint,
				});
			});

		return () => {
			isMounted = false;
		};
	}, [
		loadInfo.data,
		loadInfo.error,
		loadInfo.endpoint,
		endpoint,
		dataKey,
		dataLabel,
		dataPayload,
		forceDataSetting,
		onHttpCallComplete,
		makeHttpCall,
	]);

	return loadInfo;
}

// function useRemoteData<T>(props: Props) {
// 	const url = props.url ?? props.endpoint ?? '';
// 	const queryKey: QueryKey = props.queryKey ?? ([url] as const);

// 	const options: QueryOptions = props.options ?? { queryKey };

// 	return useHubApi<T>({
// 		url,
// 		// options,
// 		reseponseType: props.asBlob ? 'blob' : 'json',
// 	});
// }

export default useRemoteData;
