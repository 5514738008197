import { lazy } from 'react';
import type { RouteObject } from './interfaces';

const APP_ROUTES: RouteObject[] = [
	//add routes here

	{ path: '/equiano/logout', element: lazy(() => import('pages/equiano/Logout')) },

	{
		path: '/equiano/capacity/new-request',
		element: lazy(() => import('pages/equiano/NewCapacityRequest')),
	},
	{
		path: '/equiano/capacity/requests',
		element: lazy(() => import('pages/equiano/CapacityRequests')),
	},
	{
		path: '/equiano/capacity/activations',
		element: lazy(() => import('pages/equiano/activation')),
	},
	{
		path: '/equiano/capacity/activations/new',
		element: lazy(() => import('pages/equiano/activation/activation-request')),
	},
	{
		path: '/equiano/tickets/new',
		element: lazy(() => import('pages/equiano/ticketing/new-ticket')),
	},
	{
		path: '/equiano/tickets',
		element: lazy(() => import('pages/equiano/ticketing')),
	},
	{
		path: '/equiano/',
		// element: lazy(() => import('pages/equiano/Home')),
		element: lazy(() => import('pages/equiano/CapacityRequests')),
	},
];

export default APP_ROUTES;
