import { lazy } from "react";
import type { RouteObject } from "./interfaces";

const ProgrammesPage = lazy(
  () => import("commercial/project-management/projects/ProjectsPage")
);
const ProjectsPage = lazy(
  () => import("commercial/project-management/projects/AllProjectsPage")
);
const SingleProjectPage = lazy(
  () =>
    import("commercial/project-management/projects/Project/SingleProjectPage")
);

const SOFSPage = lazy(
  () => import("commercial/project-management/sofs/SOFSPage")
);
const SOFServicesPage = lazy(
  () => import("commercial/project-management/sofs/SOFServices")
);
const SingleSOFServicePage = lazy(
  () => import("commercial/project-management/sofs/Services/SingleSOFService")
);
const ServicesPage = lazy(
  () => import("commercial/project-management/sofs/Services/AllServices")
);

const ClientGroupTicketsAndServices = lazy(
  () => import("commercial/clients/ClientGroupTicketsAndServices")
);
const ClientTicketsAndServices = lazy(
  () => import("commercial/clients/ClientTicketsAndServices")
);
const SalesDashboard = lazy(() => import("commercial/sales/sales-force"));

const COMMERCIAL_ROUTES: RouteObject[] = [
  /* PROGRAMMES & PROJECTS */
  { path: "/programmes", element: ProgrammesPage },
  { path: "/programmes/:programme", element: ProgrammesPage },
  { path: "/projects/:project_id", element: SingleProjectPage },
  { path: "/projects", element: ProjectsPage },
  { path: "/projects/:project_id", element: ProgrammesPage },

  /* SOFS & SERVICES */
  { path: "/sofs/:sofId", element: SOFServicesPage },
  { path: "/sofs", element: SOFSPage },
  { path: "/services", element: ServicesPage },
  { path: "/services/:service", element: SingleSOFServicePage },
  { path: "/sofs/:sofId/services", element: SOFServicesPage },
  { path: "/sofs/:sofId/services/:service", element: SingleSOFServicePage },

  /* CLIENT */
  { path: "/client-groups", element: ClientGroupTicketsAndServices },
  { path: "/clients/:clientId", element: ClientTicketsAndServices },

  /* SALES */
  { path: "/sales/reports", element: SalesDashboard },
];

export default COMMERCIAL_ROUTES;
