import {
  HttpResponse,
  NETWORK_DATA_ENDPOINTS,
  ResponsePayload,
} from "components/Util/http-util";

const GET = "GET";
const POST = "POST";

const OK_STATUS = 200;
const UNAUTHORIZED_STATUS = 401;

export type HttpPayload = any | Record<string | number, unknown> | null;

export type ApiResponse<T> = T | HttpResponse<T>;

const makeHttpCallV1 = async <T>(
  endpoint: string,
  method = GET,
  asBlob = false,
  body: HttpPayload = null
) => {
  const requestConfig: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    mode: "cors",
    credentials: "include",
  };

  if (document.cookie) {
    if (!requestConfig.headers) requestConfig.headers = {};
    requestConfig.headers["X-CSRF-TOKEN"] =
      document.cookie
        ?.split("; ")
        .find((row) => row.startsWith("csrf_access_token="))
        ?.split("=")[1] ?? null;
  }

  if (method !== GET && body) {
    requestConfig.body = JSON.stringify(body);
  }

  let apiUrl = `${process.env.REACT_APP_HUB_API_URL}/api/${endpoint}`;

  if (endpoint.startsWith("https://")) apiUrl = endpoint;

  const route = endpoint.includes("?")
    ? endpoint.split("?")[0]
    : endpoint.split("/")[0];

  if (
    NETWORK_DATA_ENDPOINTS.includes(route) ||
    NETWORK_DATA_ENDPOINTS.includes(endpoint)
  )
    apiUrl =
      process.env.NODE_ENV === "production"
        ? `${process.env.REACT_APP_HUB_SERVICES_API_URL}/nds/api/${endpoint}`
        : `${process.env.REACT_APP_HUB_SERVICES_API_URL}/api/${endpoint}`;

  const response = await fetch(apiUrl, requestConfig);
  if (!response.ok || response.status !== OK_STATUS) {
    if (response.status === UNAUTHORIZED_STATUS) {
      //clear session storage access token to force user to login again
      // sessionStorage.clear();
      // document.cookie = "csrf_access_token=; Max-Age=0";
      // window.location = "/login";
    }

    throw new Error(getError(response));
  }

  return await (asBlob ? response.blob() : (response.json() as T));
};

const makeHttpCall = async <T>(
  endpoint: string,
  method = GET,
  asBlob = false,
  body: HttpPayload = null
) => {
  const promise = makeHttpCallV1<T>(
    endpoint,
    method,
    asBlob,
    body
  ) as Promise<T>;
  return promise.catch((e) => {
    console.log("error", e);
  }) as Promise<T>;
};

const getError = (response: Response) => {
  // const apiResponse = {
  // 	success: false,
  // 	error: null,
  // 	data: null,
  // };

  switch (response.status) {
    case 504:
      return "Connection error.";
    case 422:
    case UNAUTHORIZED_STATUS:
      return "Login session expired. Please login again";
    default:
      break;
  }

  return `An error has occured: ${response.status}`;
};

export async function fetchJsonFromApiJsonResponse<T = any>(endpoint: string) {
  return makeHttpCall<T>(endpoint) as Promise<T>;
}

export async function apiGet<T = ResponsePayload>(endpoint: string) {
  return fetchJsonFromApiJsonResponse<HttpResponse<T>>(endpoint) as T;
}

export async function fetchJsonFromApiBlobResponse(endpoint: string) {
  return makeHttpCall<Blob>(endpoint, GET, true);
}

export async function postDataToApiJsonResponse<T = any>(
  endpoint: string,
  body: HttpPayload
) {
  return makeHttpCall<T>(endpoint, POST, false, body) as unknown as T;
}

export async function apiPost<T = ResponsePayload>(
  endpoint: string,
  body: HttpPayload
) {
  return postDataToApiJsonResponse<HttpResponse<T>>(endpoint, body);
}

export async function postDataToApiBlobResponse(endpoint: string, body: any) {
  return makeHttpCall<Blob>(endpoint, POST, true, body);
}

/**
 *
 * HUB SERVICES
 *
 */

export async function hubServicesApiGet<T = ResponsePayload>(endpoint: string) {
  if (!endpoint?.startsWith("http")) {
    endpoint = `${process.env.REACT_APP_HUB_SERVICES_API_URL}/api/${endpoint}`;
  }

  return fetchJsonFromApiJsonResponse<HttpResponse<T>>(endpoint) as T;
}

export async function hubServicesApiGetBlob(endpoint: string) {
  if (!endpoint?.startsWith("http")) {
    endpoint = `${process.env.REACT_APP_HUB_SERVICES_API_URL}/api/${endpoint}`;
  }
  return makeHttpCall<Blob>(endpoint, GET, true);
}

export async function hubServicesApiPost<T = ResponsePayload>(
  endpoint: string,
  body: HttpPayload
) {
  if (!endpoint?.startsWith("http")) {
    endpoint = `${process.env.REACT_APP_HUB_SERVICES_API_URL}/api/${endpoint}`;
  }
  return postDataToApiJsonResponse<HttpResponse<T>>(endpoint, body);
}

export async function hubServicesApiPostBlobResponse(
  endpoint: string,
  body: any
) {
  if (!endpoint?.startsWith("http")) {
    endpoint = `${process.env.REACT_APP_HUB_SERVICES_API_URL}/api/${endpoint}`;
  }
  return makeHttpCall<Blob>(endpoint, POST, true, body);
}
