import React, { useMemo, useState } from 'react';
import MUIDataTable, { MUIDataTableColumnOptions, MUIDataTableProps } from 'mui-datatables';
import type { MUIDataTableOptions, MUIDataTableColumn } from 'mui-datatables';

const DefaultTableOptions: MUIDataTableOptions = {
	selectableRows: 'none',
	rowsPerPage: 15,
	rowsPerPageOptions: [],
	print: false,
	download: false,
	viewColumns: false,
	tableBodyHeight: 'auto',
	searchOpen: false,
	searchAlwaysOpen: false,
	searchPlaceholder: '',
	textLabels: undefined,
	downloadOptions: undefined,
	search: true,
	filter: true,
	searchText: '',
	pagination: true,
	expandableRows: false,
	expandableRowsHeader: false,
	expandableRowsOnClick: false,
	rowsExpanded: [],
	jumpToPage: true,
};

export interface TableColumn<T = Record<string, any>> {
	name: keyof T;
	label?: string;
	options?: MUIDataTableColumnOptions;
}

export type TableProps<T = Record<string, any> | object> = {
	title?: string | React.ReactNode;
	className?: string;
	data: T[];
	columns: string[] | MUIDataTableColumn[] | TableColumn<T>[];
	options: MUIDataTableOptions;
	onFiltersUpdate?: (filters: string[][]) => void;
	components?: MUIDataTableProps['components'];
};

/**
 *
 * @param list mui table filters
 * @param join filter string separator
 * @returns flatten filters list to a single string to include in download name
 */
const flattenList = (list: string[] | string[][] = [], join = '_'): string => {
	if (list.length === 0) return '';
	const cumulativeFilters: string[] = [];
	list.forEach((filter: string | string[]) => {
		if (filter.length === 0) return;
		cumulativeFilters.push((Array.isArray(filter) ? filter.flat(1).join(join) : filter.trim()).trim());
	});
	return cumulativeFilters.join(join + join);
};

const DEFAULT_NAME = 'Download';

const TMuiTable = ({ title = '', columns = [], className = '', data = [], options, onFiltersUpdate, components }: TableProps) => {
	const [downloadName, setDownloadName] = useState(options?.downloadOptions?.filename ?? DEFAULT_NAME);
	const tableOptions = useMemo(() => {
		const {
			// isMainContent,
			selectableRows = 'none',
			setRowProps,
			customToolbar,
			rowsPerPageOptions = [15, 30, 50],
			customSearch,
			searchOpen,
			searchProps,
			searchAlwaysOpen,
			...toolbarOptions
		} = options;

		const tableOptions: MUIDataTableOptions = {
			...DefaultTableOptions,
			rowsPerPage: 15,
			selectableRows,
			...toolbarOptions,
		};

		tableOptions.rowsPerPageOptions = rowsPerPageOptions;
		// if (!isMainContent) tableOptions.elevation = 0;
		if (setRowProps) tableOptions.setRowProps = setRowProps;
		if (customToolbar) tableOptions.customToolbar = customToolbar;
		if (customSearch) tableOptions.customSearch = customSearch;

		tableOptions.searchOpen = searchOpen;
		tableOptions.searchAlwaysOpen = searchAlwaysOpen;

		//check search props
		if (searchProps) {
			if (searchProps.onKeyUp) {
				if (!tableOptions.searchProps) tableOptions.searchProps = {};
				tableOptions.searchProps.onKeyUp = searchProps.onKeyUp;
			}
		}

		if (options.searchPlaceholder) tableOptions.searchPlaceholder = options.searchPlaceholder;

		if (tableOptions.jumpToPage === undefined) {
			tableOptions.jumpToPage = true;
			if (!tableOptions.textLabels) tableOptions.textLabels = {};
			if (!tableOptions.textLabels.pagination) tableOptions.textLabels.pagination = {};
			tableOptions.textLabels.pagination.jumpToPage = 'Go to page';
		}

		if (tableOptions.download || tableOptions.download === undefined) {
			if (!tableOptions.downloadOptions?.filterOptions) {
				if (!tableOptions.downloadOptions) tableOptions.downloadOptions = {};
				tableOptions.downloadOptions.filterOptions = {
					useDisplayedColumnsOnly: true,
					useDisplayedRowsOnly: true,
				};
			} else {
				tableOptions.downloadOptions.filterOptions.useDisplayedRowsOnly = true;
				if (tableOptions.downloadOptions.filterOptions.useDisplayedColumnsOnly === undefined) {
					tableOptions.downloadOptions.filterOptions.useDisplayedColumnsOnly = true;
				}
			}
		}

		if (!tableOptions.onFilterChange) {
			tableOptions.onFilterChange = (changedColumn, filterList) => {
				Promise.all([flattenList(filterList)]).then(filterStr => {
					setDownloadName(existing => getDownloadName(existing, filterStr[0]));
					if (typeof onFiltersUpdate === 'function') onFiltersUpdate(filterList);
				});
			};
		}

		return tableOptions;
	}, [options, onFiltersUpdate]);

	const SEPARATOR = ':-';
	const getDownloadName = (existing: string, filterStr: string) => {
		//get the record name without the filters
		const recordName = existing?.includes(SEPARATOR) ? String(existing?.split(SEPARATOR)?.[1] ?? '').trim() : existing;
		return `${filterStr} ${SEPARATOR} ${recordName}`;
	};

	const mTableOptions = useMemo(() => {
		if (tableOptions.download || tableOptions.download === undefined) {
			if (!tableOptions.downloadOptions) tableOptions.downloadOptions = {};
			tableOptions.downloadOptions.filename = downloadName.replace(SEPARATOR, '');
		}

		return tableOptions;
	}, [downloadName, tableOptions]);

	return (
		<div>
			<MUIDataTable title={title} options={mTableOptions} columns={columns} data={data} {...{ className }} components={components} />
		</div>
	);
};

export type { MUIDataTableOptions, MUIDataTableColumn };

export default TMuiTable;
