import { lazy } from "react";
import { RouteObject } from "utils/chakra/types/route";

const OAMS_APP_ROUTES: RouteObject[] = [
  //add routes here
  {
    path: "/oams/map",
    element: lazy(() => import("oams/pages/map")),
  },
  {
    path: "/oams",
    element: lazy(() => import("oams/pages")),
  },
];

export default OAMS_APP_ROUTES;
