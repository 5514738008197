import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { useHistory } from "react-router";

const ENVIROMENT = "environment";
const USER = "user";

const QRScanner = ({ openQRScanner, setOpenQRScanner, fontSize, window_width, popScanProps }) => {
	const [cameraFacingMode, setCameraFacingMode] = useState(ENVIROMENT);

	const history = useHistory();

	if (!openQRScanner) return null;

	const onQRScan = scannedData => {
		if (scannedData !== null) {
			if (scannedData.startsWith("https://hub.wiocc.net")) {
				if (scannedData.includes("/pop_audit/")) {
					const { entry } = popScanProps ?? { entry: true };
					const hubUrl = new URL(scannedData);
					const isEntryQR = hubUrl.searchParams.get("entry").toLocaleLowerCase() === "true";

					if (isEntryQR !== entry) {
						setOpenQRScanner(false, false);
						return alert(`Please scan PoP ${entry ? "entry" : "exit"} QR code`);
					}

					setOpenQRScanner(false, true);

					const [path, pop_code] = hubUrl.pathname.substring(1).split("/");

					history.replace(`/${path}`, { pop_code });
				} else {
					setOpenQRScanner(false);
					window.open(scannedData, "_self");
				}
			} else {
				setOpenQRScanner(false);
				alert("Invalid WIOCC Stock!");
			}
		}
	};

	return (
		<div
			className="qr-scanner-wrapper"
			style={{
				height: "100%",
				width: "98%",
				position: "fixed",
				zIndex: 50,
				textAlign: "center",
				left: "1%",
				backgroundColor: "#e9ecef",
				fontFamily: "Montserrat-SemiBold",
				fontSize: fontSize * 1.2,
				color: "#20358c",
			}}
		>
			<div
				style={{
					marginTop: "10px",
					cursor: "pointer",
				}}
				onClick={() => setOpenQRScanner(false)}
			>
				Close&nbsp;
				<i className="fa fa-window-close" aria-hidden="true"></i>
			</div>
			<QrReader
				delay={300}
				style={{
					width: window_width < 500 ? "98%" : "40%",
					marginLeft: "auto",
					marginRight: "auto",
				}}
				onError={err => console.log(err)}
				onScan={onQRScan}
				facingMode={cameraFacingMode}
			/>
			<div
				style={{
					marginBottom: "10px",
					marginTop: "10px",
					cursor: "pointer",
				}}
				onClick={() => {
					setCameraFacingMode(cameraFacingMode === ENVIROMENT ? USER : ENVIROMENT);
				}}
			>
				Switch Camera&nbsp;
				<i className="fa fa-sync" aria-hidden="true"></i>
			</div>
		</div>
	);
};

export default QRScanner;
