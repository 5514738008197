// 'use strict';
const { _objectWithoutProperties, _extends } = require("../common/ReactBootstrapIconsUtil");

Object.defineProperty(exports, "__esModule", { value: true });

function _interopDefault(ex) {
	return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}

var React = _interopDefault(require("react"));
var PropTypes = _interopDefault(require("prop-types"));

var Diagram3 = function Diagram3(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z",
			clipRule: "evenodd",
		})
	);
};

Diagram3.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Diagram3.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var SortAlphaDown = function SortAlphaDown(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M4 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11A.5.5 0 0 1 4 2z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M6.354 11.146a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L4 12.793l1.646-1.647a.5.5 0 0 1 .708 0z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			d: "M9.664 7l.418-1.371h1.781L12.281 7h1.121l-1.78-5.332h-1.235L8.597 7h1.067zM11 2.687l.652 2.157h-1.351l.652-2.157H11zM9.027 14h3.934v-.867h-2.645v-.055l2.567-3.719v-.691H9.098v.867h2.507v.055l-2.578 3.719V14z",
		})
	);
};

SortAlphaDown.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
SortAlphaDown.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var SortAlphaUpAlt = function SortAlphaUpAlt(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M4 14a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-1 0v11a.5.5 0 0 0 .5.5z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M6.354 4.854a.5.5 0 0 0 0-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L4 3.207l1.646 1.647a.5.5 0 0 0 .708 0z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			d: "M9.027 7h3.934v-.867h-2.645v-.055l2.567-3.719v-.691H9.098v.867h2.507v.055L9.027 6.309V7zm.637 7l.418-1.371h1.781L12.281 14h1.121l-1.78-5.332h-1.235L8.597 14h1.067zM11 9.687l.652 2.157h-1.351l.652-2.156H11z",
		})
	);
};

SortAlphaUpAlt.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
SortAlphaUpAlt.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var Bezier2 = function Bezier2(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M1 2.5A1.5 1.5 0 0 1 2.5 1h1A1.5 1.5 0 0 1 5 2.5h4.134a1 1 0 1 1 0 1h-2.01c.18.18.34.381.484.605.638.992.892 2.354.892 3.895 0 1.993.257 3.092.713 3.7.356.476.895.721 1.787.784A1.5 1.5 0 0 1 12.5 11h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5H6.866a1 1 0 1 1 0-1h1.711a2.839 2.839 0 0 1-.165-.2C7.743 11.407 7.5 10.007 7.5 8c0-1.46-.246-2.597-.733-3.355-.39-.605-.952-1-1.767-1.112A1.5 1.5 0 0 1 3.5 5h-1A1.5 1.5 0 0 1 1 3.5v-1zM2.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm10 10a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z",
			clipRule: "evenodd",
		})
	);
};

Bezier2.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Bezier2.defaultProps = {
	color: "currentColor",
	size: "1em",
};

exports.Diagram3 = Diagram3;
exports.SortAlphaDown = SortAlphaDown;
exports.SortAlphaUpAlt = SortAlphaUpAlt;
exports.Bezier2 = Bezier2;
