import TBlankPage from 'common/Pages/TBlankPage';
import React, { useEffect } from 'react';
import { ClientNetworkTicket } from '../interfaces';
import { Box, Flex, Grid, GridItem, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import BugReport from '@mui/icons-material/BugReport';
import BusinessSharp from '@mui/icons-material/BusinessSharp';
import CalendarToday from '@mui/icons-material/CalendarToday';
import CategorySharp from '@mui/icons-material/CategorySharp';
import Description from '@mui/icons-material/Description';
import History from '@mui/icons-material/History';
import EmailSharp from '@mui/icons-material/EmailSharp';
import AccountCircleSharp from '@mui/icons-material/AccountCircleSharp';
import InfoSharp from '@mui/icons-material/InfoSharp';
import LanSharp from '@mui/icons-material/LanSharp';
import PriorityHighSharp from '@mui/icons-material/LanSharp';
import TopicSharp from '@mui/icons-material/TopicSharp';
import TripOriginSharp from '@mui/icons-material/TripOriginSharp';
import WifiTetheringOffSharpIcon from '@mui/icons-material/WifiTetheringOffSharp';
import NoMatchingRecords, { LoadingAndIcon } from './ntt-util-components';
import '../network-ticket.css';
import { getCrossSiteSafeHtml, useMuiTextFieldStyles } from '../util';
import useRemoteData from 'common/hooks/useTRemoteData';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useParams } from 'react-router';
import TMuiTable, { MUIDataTableColumn } from 'common/Table/TMuiTable';

interface Props {
	ticket_id: string;
}
export default function ClientNetworkTicketView() {
	const { ticket_id } = useParams<Props>();
	const history = useHistory();
	const formFieldColumns = 'repeat(3, 1fr)';
	const boxShadowProps = { borderRadius: '0 0 8px 8px' };
	const colGap = 6;
	const fieldSpacingBtn = 8;
	const fieldSpaceAndTabs = 4;
	const tabStyle = { borderTopLeftRadius: '10px', borderTopRightRadius: '10px', margin: '2px' };
	const { addToast } = useToasts();
	const {
		loading = false,
		error,
		data: ticketData,
	} = useRemoteData<ClientNetworkTicket>({
		endpoint: `network-tickets/client-tickets/${ticket_id}`,
		dataKey: 'data',
		dataLabel: 'Network Tickets',
		forceDataSetting: true,
	});

	const textFieldsClasses = useMuiTextFieldStyles();

	useEffect(() => {
		if (error) {
			addToast(`Could not proceed: ${error}`, { appearance: 'warning', autoDismiss: true });
			history.goBack();
		}
	}, [addToast, error, history, loading]);

	return (
		<TBlankPage
			pageTitle={`Client Network Ticket :: [${ticketData?.ticket_name} - ${ticketData?.status}] - ${ticketData?.priority}`}
			bodyProps={{ pt: 1, px: 2 }}
			titleProps={{ p: 2, m: 0 }}
			showTitle
			showBackButton={true}
		>
			<Flex w="full" align="center" justifyContent="center" direction="column">
				<PrimaryInformation />
				<IncidentalInformation />
				<ClosureDetails />
			</Flex>
		</TBlankPage>
	);

	function UpdatesTable(props) {
		const columnNames: MUIDataTableColumn[] = [
			{ name: 'last_modified', label: 'Updated On' },
			{ name: 'type', label: 'Type' },
			{ name: 'updated_by', label: 'Updated By' },
			{
				name: '',
				label: 'Update',
				options: {
					filter: false,
					customBodyRenderLite: index => {
						const row = props?.updates?.[index] ?? {};
						return <div dangerouslySetInnerHTML={getCrossSiteSafeHtml(row.update)}></div>;
					},
				},
			},
		];

		return (
			<TMuiTable
				columns={columnNames}
				data={ticketData ? ticketData?.updates : []}
				options={{ download: true }}
				className="h-mui-table table-md mt-2"
			/>
		);
	}

	function ServicesTable(props) {
		const columnNames: MUIDataTableColumn[] = [
			{ name: 'service_name', label: 'Name' },
			{ name: 'client', label: 'Client' },
			{ name: 'capacity', label: 'Capacity' },
			{ name: 'city_a', label: 'A-City' },
			{ name: 'city_b', label: 'B-City' },
			{ name: 'impact', label: 'Impact' },
		];

		return (
			<TMuiTable columns={columnNames} data={props.services} options={{ download: true }} className="h-mui-table table-md mt-2" />
		);
	}

	function ClosureDetails() {
		return (
			<Box className={`${'card'}`} {...boxShadowProps}>
				<CardHeader subheader="Closure Details" className="card-header" />
				<CardContent>
					<Box w="full">
						<Box>
							<Grid templateColumns={formFieldColumns} gap={colGap} w="full" mt={fieldSpaceAndTabs}>
								<GridItem colSpan={1}>
									<TextField
										label="Sub Category"
										classes={{ root: textFieldsClasses.root }}
										InputProps={{
											readOnly: true,
											startAdornment: (
												<InputAdornment position="start">
													<LoadingAndIcon loading={loading} icon={<CategorySharp />} />
												</InputAdornment>
											),
										}}
										id={ticketData?.sub_category}
										defaultValue=""
										size="small"
										className="form-fields"
										placeholder="Sub category..."
										value={ticketData?.sub_category}
									/>
								</GridItem>
								<GridItem colSpan={1}>
									<TextField
										label="Failure Point A"
										classes={{ root: textFieldsClasses.root }}
										InputProps={{
											readOnly: true,
											startAdornment: (
												<InputAdornment position="start">
													<LoadingAndIcon loading={loading} icon={<BugReport />} />
												</InputAdornment>
											),
										}}
										id={ticketData?.sub_category}
										defaultValue=""
										size="small"
										className="form-fields"
										placeholder="Failure point A..."
										value={ticketData?.failure_point_a_end}
									/>
								</GridItem>
								<GridItem colSpan={1}>
									<TextField
										label="Failure Point B"
										classes={{ root: textFieldsClasses.root }}
										InputProps={{
											readOnly: true,
											startAdornment: (
												<InputAdornment position="start">
													<LoadingAndIcon loading={loading} icon={<BugReport />} />
												</InputAdornment>
											),
										}}
										id={ticketData?.sub_category}
										defaultValue=""
										size="small"
										className="form-fields"
										placeholder="Failure point B..."
										value={ticketData?.failure_point_b_end}
									/>
								</GridItem>
								<GridItem colSpan={1}>
									<TextField
										label="Client RFO"
										classes={{ root: textFieldsClasses.root }}
										InputProps={{
											readOnly: true,
											startAdornment: (
												<InputAdornment position="start">
													<LoadingAndIcon loading={loading} icon={<InfoSharp />} />
												</InputAdornment>
											),
										}}
										id={ticketData?.sub_category}
										defaultValue=""
										size="small"
										className="form-fields"
										placeholder="Client RFO..."
										value={ticketData?.client_rfo}
									/>
								</GridItem>
							</Grid>
						</Box>
						<Tabs aria-label="More ticket details" onChange={() => {}} variant="enclosed-colored" defaultIndex={0} mt={4}>
							<TabList>
								<Tab value={2} style={{ ...tabStyle }}>
									<LoadingAndIcon loading={loading} icon={<History />} /> Updates
								</Tab>
								<Tab value={1} style={{ ...tabStyle }}>
									<LoadingAndIcon loading={loading} icon={<WifiTetheringOffSharpIcon />} /> Services
								</Tab>
							</TabList>
							<TabIndicator height="2px" bg="blue.500" borderRadius="2px" />
							<TabPanels>
								<TabPanel>
									{ticketData?.updates !== undefined && ticketData?.updates.length > 0 ? (
										<UpdatesTable updates={ticketData.updates} />
									) : (
										<NoMatchingRecords type="updates" />
									)}
								</TabPanel>
								<TabPanel>
									{ticketData?.affected_services !== undefined && ticketData?.affected_services.length > 0 ? (
										<ServicesTable services={ticketData.affected_services} />
									) : (
										<NoMatchingRecords type="services" />
									)}
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Box>
				</CardContent>
			</Box>
		);
	}

	function IncidentalInformation() {
		return (
			<Box className="card" {...boxShadowProps}>
				<CardHeader subheader="Incidental Information" className="card-header" />
				<CardContent>
					<Grid templateColumns={formFieldColumns} gap={colGap} w="full" mt={fieldSpaceAndTabs}>
						<GridItem colSpan={1}>
							<TextField
								label="Subject"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<TopicSharp />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.network_ticket}
								defaultValue=""
								size="small"
								className="form-fields"
								placeholder="Subject..."
								value={ticketData?.subject}
								multiline
							/>
						</GridItem>
						<GridItem colSpan={1}>
							<TextField
								label="Origin"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<TripOriginSharp />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.subject}
								defaultValue=""
								size="small"
								className="form-fields"
								placeholder="Origin..."
								value={ticketData?.origin}
							/>
						</GridItem>
						<GridItem colSpan={1}>
							<TextField
								label="Client Ref #"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<InfoSharp />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.origin}
								defaultValue=""
								size="small"
								className="form-fields"
								placeholder="Client ref..."
								value={ticketData?.client_ticket_ref}
							/>
						</GridItem>
					</Grid>
					<Grid templateColumns={formFieldColumns} gap={colGap} w="full" mt={fieldSpacingBtn}>
						<GridItem colSpan={1}>
							<TextField
								label="Status"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<InfoSharp />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.failure_point_a_end}
								defaultValue=""
								size="small"
								className="form-fields"
								placeholder="Status..."
								value={ticketData?.status}
							/>
						</GridItem>
						<GridItem colSpan={1}>
							<TextField
								label="Incident Start Date"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<CalendarToday />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.failure_point_b_end}
								defaultValue=""
								size="small"
								className="form-fields"
								placeholder="Incident start date..."
								value={ticketData?.incident_start_date}
							/>
						</GridItem>
						<GridItem colSpan={1}>
							<TextField
								label="Related Supplier Ticket"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<InfoSharp />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.incident_end_date}
								defaultValue=""
								size="small"
								className="form-fields"
								placeholder="Related supplier ticket..."
								value={ticketData?.related_supplier_tt}
							/>
						</GridItem>
					</Grid>
					<Grid templateColumns={formFieldColumns} gap={colGap} w="full" mt={fieldSpacingBtn}>
						<GridItem colSpan={1}>
							<TextField
								label="Priority"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<PriorityHighSharp />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.sub_category}
								defaultValue=""
								size="small"
								className="form-fields"
								placeholder="Priority..."
								value={ticketData?.priority}
							/>
						</GridItem>
						<GridItem colSpan={1}>
							<TextField
								label="Incident End Date"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<CalendarToday />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.related_supplier_tt}
								defaultValue=""
								size="small"
								className="form-fields"
								placeholder="Incident end date..."
								value={ticketData?.incident_end_date}
							/>
						</GridItem>
					</Grid>
				</CardContent>
			</Box>
		);
	}

	function PrimaryInformation() {
		return (
			<Box className="card" {...boxShadowProps}>
				<CardHeader subheader="Primary Information" className="card-header" />
				<CardContent>
					<Grid templateColumns={formFieldColumns} gap={colGap} w="full" mt={fieldSpaceAndTabs}>
						<GridItem colSpan={1}>
							<TextField
								label="Name"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<Description />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.ticket_name}
								defaultValue=""
								size="small"
								placeholder="Ticket name..."
								className="form-fields"
								value={ticketData?.ticket_name}
							/>
						</GridItem>
						<GridItem colSpan={1}>
							<TextField
								label="Assigned To"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<AccountCircleSharp />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.type}
								defaultValue=""
								size="small"
								className="form-fields"
								value={ticketData?.assigned_to}
								placeholder="Assigned to..."
							/>
						</GridItem>
						<GridItem colSpan={1}>
							<TextField
								label="Network Ticket"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<LanSharp />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.status}
								defaultValue=""
								size="small"
								className="form-fields"
								value={ticketData?.network_ticket}
								placeholder="Network ticket..."
							/>
						</GridItem>
					</Grid>
					<Grid templateColumns={formFieldColumns} gap={colGap} w="full" mt={fieldSpacingBtn}>
						<GridItem colSpan={1}>
							<TextField
								label="Client"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<BusinessSharp />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.incident_start_date}
								defaultValue=""
								size="small"
								className="form-fields"
								value={ticketData?.client}
								placeholder="Client..."
							/>
						</GridItem>
						<GridItem colSpan={1}>
							<TextField
								label="Type"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<CategorySharp />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.priority}
								defaultValue=""
								size="small"
								className="form-fields"
								value={ticketData?.type}
								placeholder="Type..."
							/>
						</GridItem>
						<GridItem colSpan={1}>
							<TextField
								label="Service Manager Email"
								classes={{ root: textFieldsClasses.root }}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<LoadingAndIcon loading={loading} icon={<EmailSharp />} />
										</InputAdornment>
									),
								}}
								id={ticketData?.priority}
								defaultValue=""
								size="small"
								className="form-fields"
								value={ticketData?.service_manager_email}
								placeholder="Service manager email..."
							/>
						</GridItem>
					</Grid>
				</CardContent>
			</Box>
		);
	}
}
