import React from "react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { appTheme } from "utils/theme";
import {
  MUI_THEME_IDENTIFIER,
  getMuiTheme,
} from "utils/theme/mui-theme-provider";
import { useAppSelector } from "../store/hooks";
import { shallowEqual } from "react-redux";

interface Props {
  children: React.ReactNode;
}

const AppChakraProvider = ({ children }: Props) => {
  const theme = useAppSelector((state) => state.theme, shallowEqual);

  return (
    <>
      <ColorModeScript initialColorMode={appTheme.config.initialColorMode} />
      <ChakraProvider
        theme={{
          ...appTheme,
          [MUI_THEME_IDENTIFIER]: getMuiTheme(theme.colorMode),
        }}
      >
        {children}
      </ChakraProvider>
    </>
  );
};

export default AppChakraProvider;
