import React, { useCallback, useEffect, useState } from 'react';
import { userIsUsingSharedAccount } from './user-utils';
import NetsuiteSharedAccountsModal from 'common/NetsuiteSharedAccountsModal';
import ExtensionDownloadModal from 'common/ExtensionDownloadModal';
import { fetchJsonFromApiJsonResponse } from 'common/FetchFunctions';
import { Link } from 'react-router-dom';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import MenuItem from '@mui/material/MenuItem';

const useSharedAccounts = props => {
	const { globalState, globalDispatch } = props;

	const [showNetsuiteSharedAccountsModal, setShowNetsuiteSharedAccountsModal] = useState(false);
	const [netsuiteSharedAccounts, setNetsuiteSharedAccounts] = useState([]);
	const [showExtensionModal, setShowExtensionModal] = useState(false);
	const [loadingNetSuiteSharedAccounts, setLoadingNetSuiteSharedAccounts] = useState(true);
	const [showNetSuiteSharedAccountsOptions, setShowNetSuiteSharedAccountsOptions] = useState(false);

	const updateWidthAndHeight = useCallback(() => {
		globalDispatch({
			type: 'SETWINDOWDIMENSIONS',
			window_height: window.outerHeight,
			window_width: window.outerWidth,
		});
	}, [globalDispatch]);

	useEffect(() => {
		let isMounted = true;
		const onWindowResize = () => isMounted && updateWidthAndHeight();
		window.addEventListener('resize', onWindowResize);
		return () => {
			isMounted = false;
			window.removeEventListener('resize', onWindowResize);
		};
	}, [updateWidthAndHeight]);

	const usingSharedAccount = userIsUsingSharedAccount(globalState);

	const showModals = () => {
		return (
			<>
				<NetsuiteSharedAccountsModal
					show={showNetsuiteSharedAccountsModal}
					// show={false}
					// show={globalState.userDetails.using_shared_netsuite_account ? false : true}
					globalState={globalState}
					globalDispatch={globalDispatch}
					setShowNetsuiteSharedAccountsModal={setShowNetsuiteSharedAccountsModal}
					setShowExtensionModal={setShowExtensionModal}
					netsuiteSharedAccounts={netsuiteSharedAccounts}
					setNetsuiteSharedAccounts={setNetsuiteSharedAccounts}
					loadingNetSuiteSharedAccounts={loadingNetSuiteSharedAccounts}
					setLoadingNetSuiteSharedAccounts={setLoadingNetSuiteSharedAccounts}
					showNetSuiteSharedAccountsOptions={showNetSuiteSharedAccountsOptions}
					setShowNetSuiteSharedAccountsOptions={setShowNetSuiteSharedAccountsOptions}
					canRequestSharedAccount={usingSharedAccount}
					onHide={() => {
						setShowNetsuiteSharedAccountsModal(false);
						setShowNetSuiteSharedAccountsOptions(false);
					}}
				/>
				<ExtensionDownloadModal
					show={showExtensionModal}
					globalState={globalState}
					globalDispatch={globalDispatch}
					setShowNetsuiteSharedAccountsModal={setShowNetsuiteSharedAccountsModal}
					onHide={() => {
						setShowExtensionModal(false);
						setShowNetsuiteSharedAccountsModal(true);
					}}
				/>
			</>
		);
	};

	const onNetsuiteMenuClick = e => {
		if (!usingSharedAccount) return;
		e.preventDefault();
		setShowNetsuiteSharedAccountsModal(!showNetsuiteSharedAccountsModal);
		setLoadingNetSuiteSharedAccounts(true);
		fetchJsonFromApiJsonResponse(`getNetsuiteSharedAccounts`)
			.then(_netsuiteSharedAccounts => {
				if (_netsuiteSharedAccounts.netsuiteSharedAccounts) {
					setNetsuiteSharedAccounts(_netsuiteSharedAccounts.netsuiteSharedAccounts);
					setLoadingNetSuiteSharedAccounts(false);
				} else {
					// console.log(_tickets.msg)
				}
			})
			.catch(error => {
				console.log(error.message);
			});
	};

	const showMenu = () => {
		return (
			<MenuItem>
				<OpenInNewOutlinedIcon />
				<Link
					color="white"
					sx={{ mx: 1, '&:hover': { color: 'white' } }}
					to={usingSharedAccount ? '/' : '/netsuite'}
					{...(usingSharedAccount ? {} : { target: '_blank' })}
					onClick={onNetsuiteMenuClick}
				>
					NetSuite&nbsp;&nbsp;
				</Link>
			</MenuItem>
		);
	};

	const displayModals = () => {
		setShowNetsuiteSharedAccountsModal(!showNetsuiteSharedAccountsModal);
		setLoadingNetSuiteSharedAccounts(true);
		fetchJsonFromApiJsonResponse(`getNetsuiteSharedAccounts`)
			.then(_netsuiteSharedAccounts => {
				if (_netsuiteSharedAccounts.netsuiteSharedAccounts) {
					setNetsuiteSharedAccounts(_netsuiteSharedAccounts.netsuiteSharedAccounts);
					setLoadingNetSuiteSharedAccounts(false);
				}
			})
			.catch(error => {
				console.log(error.message);
			});
	};

	return { showModals, showMenu, onNetsuiteMenuClick, displayModals, usingSharedAccount };
};

export default useSharedAccounts;
