import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from 'react-query-devtools';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			cacheTime: 50000,
			retryOnMount: false,
			refetchOnReconnect: true,
			refetchOnWindowFocus: false,
			keepPreviousData: true,
		},
	},
});

const ReactQueryClientProvider = ({ children }: { children: React.ReactNode }) => {
	return (
		<>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
			{/* <ReactQueryDevtools initialIsOpen={false} /> */}
		</>
	);
};

export default ReactQueryClientProvider;
