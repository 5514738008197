import React from "react";
import { ToastContainer } from "react-toastify";
import MemoizedMyNavBar from "../MyNavbar";
import PageTitle, { MetaTag } from "./TPageTitle";
import Box, { BoxProps } from "@mui/material/Box";
import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

interface BlankPageProps {
  children?: React.ReactNode;
  pageTitle: string;
  metaTags?: MetaTag[];
  pathname?: string;
  bodyProps?: BoxProps;
  titleProps?: BoxProps;
  showTitle?: boolean;
  showBackButton?: boolean;
  headearProps?: BoxProps["sx"];
}

const TBlankPage = ({
  children,
  pageTitle,
  metaTags = [],
  bodyProps,
  titleProps = {},
  showTitle = false,
  showBackButton = false,
  headearProps = {},
}: BlankPageProps) => {
  const history = useHistory();
  return (
    <Box>
      <PageTitle title={pageTitle} metaTags={metaTags} />
      <Box
        sx={{ position: "sticky", top: 0, zIndex: 1020, ...headearProps }}
        id="fixedHeader"
      >
        <MemoizedMyNavBar />
        <Box
          hidden={!showTitle}
          className="shadow-sm"
          sx={{
            py: 2,
            bgcolor: "white",
            position: "sticky",
            top: 0,
            fontWeight: "bold",
            fontFamily: "Montserrat-Regular , Montserrat",
            ...titleProps,
          }}
        >
          <div
            className="row"
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "0px",
              marginLeft: "0px",
            }}
          >
            <IconButton
              icon={<ArrowBack />}
              variant="outline"
              aria-label="Back"
              onClick={() => history.goBack()}
              hidden={!showBackButton}
              mx={2.5}
              style={{ fontSize: "1.25rem" }}
            />

            <h6
              style={{
                margin: 0,
                fontWeight: "bolder",
                fontFamily: "Montserrat-Regular , Montserrat",
              }}
            >
              {pageTitle}
            </h6>
          </div>
        </Box>
      </Box>
      <ToastContainer newestOnTop={true} />

      <Box pt={2} px={3} pb={4} {...bodyProps}>
        {children}
      </Box>
    </Box>
  );
};
export default TBlankPage;
