import React, { useState } from "react";
import QRScanner from "./QRScanner";
import "./qrscanner.css";

const QRButton = ({
	window_width,
	fontSize = 10,
	qrFontSize = "10em",
	label = "",
	exiting = false,
	className = "",
	currentPath,
	postQRScanCallback = successful => {},
	otherContent,
}) => {
	const [openQRScanner, setOpenQRScanner] = useState(false);

	if (currentPath === "pop_audit") return null;

	return (
		<div className={`qr-code-container ${className}`}>
			<div data-bs-toggle="tooltip" data-bs-placement="top" title="Scan QR Code">
				<span style={{ fontSize: fontSize * 1.5 }} onClick={() => setOpenQRScanner(!openQRScanner)}>
					<i className="fa fa-qrcode" aria-hidden="true" style={{ fontSize: qrFontSize }}></i>
				</span>
				{label && <div className="w-100 text-center">{label}</div>}
			</div>

			<QRScanner
				popScanProps={{ entry: !exiting }}
				openQRScanner={openQRScanner}
				setOpenQRScanner={(open, successful) => {
					setOpenQRScanner(open);
					postQRScanCallback(successful);
				}}
				window_width={window_width}
				fontSize={fontSize}
			/>

			{!openQRScanner && otherContent}
		</div>
	);
};

export default QRButton;
