const colorPallete = {
	transparent: 'transparent',
	primary: {
		50: '#e6eeff',
		100: '#c0cbf5',
		200: '#96a8e8',
		300: '#6e85de',
		400: '#4562d3',
		500: '#2d48ba',
		600: '#213891',
		700: '#172869',
		800: '#0b1841',
		900: '#02081b',
	},
	secondary: {
		50: '#ffe3e8',
		100: '#feb6bf',
		200: '#f88894',
		300: '#f3596b',
		400: '#ee2a41',
		500: '#d51127',
		600: '#a60a1e',
		700: '#770515',
		800: '#4a010b',
		900: '#1f0001',
	},
};

export default colorPallete;
