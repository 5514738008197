export type ResponsePayload = Record<string, number | string | boolean | unknown>;
export interface HttpResponse<T = ResponsePayload> {
	data: T;
	error: string;
	success: boolean;
}

export type HttpPayload = any | Record<string | number, unknown> | null;

const ALARMS = [
	'getTejasPrtgAlarms',
	'getTopTejasPrtgAlarms',
	'getTejasAlarms',
	'getActiveTejasAlarms',
	'getPrtgAlarms',
	'getPrtgEnvironmentalAlarms',
	'ackUnackTejasAlarm',
	'getNodeIpMapping',
	'getTejasOpticalLinkAlarms',
	'getTejasPopAlarms',
	'getTejasNodePortAlarms',
	// 'getPriorityDict',
];

const CLIENTS = ['clientServices'];

const SYNC_REQUESTS = ['syncOpticalLinks', 'syncNetworkElement'];

const SERVICE_CONFIGURATIONS = ['getServicesOnNETWORKLinks', 'getServicesOnNetworkLink', 'getServicesOnNodeSlotPort'];

const HPP = ['getAllHorizonBIDSDItems'];

const NOC_REPORTS = [
	'reports/tejas-prtg',
	'reports/tejas-prtg/more-than-ten-days',
	'change-management',
	'reports/noc',
	'reports/netsuite',
	'reports/tac-updates',
	'tejas/nodes',
	'service-configs/slots',
	'service-configs/slots',
	'service-configs/ports',
	'service-configs',
	'fetchTejasNodePortsServices',
	'reports/noc/shift-summary'
];

export const NETWORK_DATA_ENDPOINTS = [
	ALARMS,
	CLIENTS,
	HPP,
	//COMMENT
	SERVICE_CONFIGURATIONS,
	SYNC_REQUESTS,
	NOC_REPORTS,
].flat(1);
