import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { LinkItem } from './types';
import { GlobalState } from 'store/interfaces';
import { userIsNmcEngineer, userIsUsingSharedAccount } from './user-utils';

function getCommonLinks(globalState: GlobalState, onNetsuiteMenuClick: React.MouseEventHandler<HTMLAnchorElement>): LinkItem[] {
	const isNmcEngineer = userIsNmcEngineer(globalState);
	const usingSharedAccount = userIsUsingSharedAccount(globalState);

	return [
		{
			title: 'Shortcuts',
			items: [
				{
					label: 'NetSuite',
					icon: usingSharedAccount ? AssignmentIndIcon : OpenInNewOutlinedIcon,
					onClick: onNetsuiteMenuClick,
					path: usingSharedAccount ? '/' : '/netsuite',
					target: '_blank',
				},
				{ label: 'Partner Portal', path: 'https://portal.wiocc.net', icon: OpenInNewOutlinedIcon, target: '_blank' },
				{ label: 'Sage', path: 'https://sage.wiocc.net/webselfservice#/', icon: OpenInNewOutlinedIcon, target: '_blank' },
				{ label: 'PDG', path: 'https://pdg.wiocc.net', icon: OpenInNewOutlinedIcon, target: '_blank' },
				...(globalState.userDetails.is_2africa_team || isNmcEngineer
					? [{ label: '2Africa Hub', path: 'https://hub.2afgera.net', icon: OpenInNewOutlinedIcon, target: '_blank' }]
					: []),

				{ label: 'Equiano', path: '/equiano', icon: OpenInNewOutlinedIcon }
			],
		},
	];
}
export default getCommonLinks;
