import React, { useEffect, useContext, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import MyLoader from "./MyLoader";
import { fetchJsonFromApiJsonResponse } from "../common/FetchFunctions";
import Context from "../../store/context";
import { userCanViewSalesReports } from "./navbar/user-utils";

export function PrivateRoute({ component: Component, ...rest }) {
  const { globalState, globalDispatch } = useContext(Context);
  const [awaitingResponse, setAwaitingResponse] = useState(true);
  useEffect(() => {
    if (
      !globalState.userDetails ||
      (globalState.userDetails && globalState.userDetails.name === null)
    ) {
      if (awaitingResponse) {
        fetchJsonFromApiJsonResponse("getUserDetails")
          .then((user) => {
            // console.log(user.user)
            if (user.user.email === "") {
              throw new Error("No email address");
            }
            if (user.user) {
              globalDispatch({
                type: "LOGIN",
              });
              if (user.user.jobTitle === null) {
                user.user.jobTitle = "Undefined";
              }
              globalDispatch({
                type: "SETUSER",
                userDetails: user.user,
              });
              setAwaitingResponse(false);

              fetchJsonFromApiJsonResponse(`users/filters`)
                .then((_filters) => {
                  if (_filters.filterState) {
                    globalDispatch({
                      type: "SETFILTERS",
                      filterDetails: _filters,
                    });
                  } else {
                    // console.log(_filters.msg)
                  }
                })
                .catch((error) => {
                  console.log(error.message);
                });
            } else {
              console.log(user.msg);
              setAwaitingResponse(false);
            }
          })
          .catch((err) => {
            console.log("Error Confirming Login " + err);
            setAwaitingResponse(false);
            globalDispatch({ type: "LOGOUT" });
          });
      } else {
        setAwaitingResponse(false);
      }
    } else {
      setAwaitingResponse(false);
    }
    return () => setAwaitingResponse(false);
  }, [awaitingResponse, globalDispatch, globalState.userDetails]);

  if (globalState.isLoggedIn === false && !awaitingResponse) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
      />
    );
  }

  if (globalState.isLoggedIn === true) {
    // return <Route {...rest} render={(props) => <Component {...props} />} />;
    // return <Route {...rest} render={() => <Component {...rest} />} />;
    // console.log(rest, globalState.userDetails.jobTitle)
    if (rest.path === "/") {
      // console.log(globalState.userDetails)
      if (globalState.userDetails.jobTitle === null) {
        return <MyLoader />;
      } else {
        if (globalState.userDetails.jobTitle.includes("Sales")) {
          return <Redirect to="/diagrams" />;
        }
      }
    }

    if (rest.path === "/sales/reports") {
      if (!userCanViewSalesReports(globalState.userDetails)) {
        return <Redirect to="/home" />;
      }
    }

    return (
      <Route
        {...rest}
        render={(props) => (
          <Component {...props} {...rest} globalState={globalState} />
        )}
      />
    );
  }
  return <MyLoader />;
}
