const { _objectWithoutProperties, _extends } = require("./ReactBootstrapIconsUtil");

// Object.defineProperty(exports, "__esModule", { value: true });

function _interopDefault(ex) {
	return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}

var React = _interopDefault(require("react"));
var PropTypes = _interopDefault(require("prop-types"));

var ExclamationSquare = function ExclamationSquare(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M14 1H2a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V2a1 1 0 00-1-1zM2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			d: "M7.002 11a1 1 0 112 0 1 1 0 01-2 0zM7.1 4.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0L7.1 4.995z",
		})
	);
};

ExclamationSquare.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
ExclamationSquare.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var BoundingBoxCircles = function BoundingBoxCircles(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M12.5 2h-9V1h9v1zm-10 1.5v9h-1v-9h1zm11 9v-9h1v9h-1zM3.5 14h9v1h-9v-1z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M14 3a1 1 0 100-2 1 1 0 000 2zm0 1a2 2 0 100-4 2 2 0 000 4zm0 11a1 1 0 100-2 1 1 0 000 2zm0 1a2 2 0 100-4 2 2 0 000 4zM2 3a1 1 0 100-2 1 1 0 000 2zm0 1a2 2 0 100-4 2 2 0 000 4zm0 11a1 1 0 100-2 1 1 0 000 2zm0 1a2 2 0 100-4 2 2 0 000 4z",
			clipRule: "evenodd",
		})
	);
};

BoundingBoxCircles.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
BoundingBoxCircles.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var FolderCheck = function FolderCheck(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M9.828 4H2.19a1 1 0 00-.996 1.09l.637 7a1 1 0 00.995.91H9v1H2.826a2 2 0 01-1.991-1.819l-.637-7a1.99 1.99 0 01.342-1.31L.5 3a2 2 0 012-2h3.672a2 2 0 011.414.586l.828.828A2 2 0 009.828 3h3.982a2 2 0 011.992 2.181L15.546 8H14.54l.265-2.91A1 1 0 0013.81 4H9.828zm-2.95-1.707L7.587 3H2.19c-.24 0-.47.042-.684.12L1.5 2.98a1 1 0 011-.98h3.672a1 1 0 01.707.293z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M15.854 10.146a.5.5 0 010 .708l-3 3a.5.5 0 01-.708 0l-1.5-1.5a.5.5 0 01.708-.708l1.146 1.147 2.646-2.647a.5.5 0 01.708 0z",
			clipRule: "evenodd",
		})
	);
};

FolderCheck.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
FolderCheck.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var FolderFill = function FolderFill(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M9.828 3h3.982a2 2 0 011.992 2.181l-.637 7A2 2 0 0113.174 14H2.826a2 2 0 01-1.991-1.819l-.637-7a1.99 1.99 0 01.342-1.31L.5 3a2 2 0 012-2h3.672a2 2 0 011.414.586l.828.828A2 2 0 009.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 006.172 2H2.5a1 1 0 00-1 .981l.006.139z",
			clipRule: "evenodd",
		})
	);
};
FolderFill.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
FolderFill.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var GraphUp = function GraphUp(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			d: "M0 0h1v16H0V0zm1 15h15v1H1v-1z",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M14.39 4.312L10.041 9.75 7 6.707l-3.646 3.647-.708-.708L7 5.293 9.959 8.25l3.65-4.563.781.624z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M10 3.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v4a.5.5 0 01-1 0V4h-3.5a.5.5 0 01-.5-.5z",
			clipRule: "evenodd",
		})
	);
};

GraphUp.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
GraphUp.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var Alt = function Alt(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M1 13.5a.5.5 0 00.5.5h3.797a.5.5 0 00.439-.26L11 3h3.5a.5.5 0 000-1h-3.797a.5.5 0 00-.439.26L5 13H1.5a.5.5 0 00-.5.5zm10 0a.5.5 0 00.5.5h3a.5.5 0 000-1h-3a.5.5 0 00-.5.5z",
			clipRule: "evenodd",
		})
	);
};

Alt.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Alt.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var Gear = function Gear(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 014.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 01-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 011.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 012.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 012.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 011.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 01-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 018.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 001.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 00.52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 00-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 00-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 00-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 00-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 00.52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 001.255-.52l.094-.319z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M8 5.754a2.246 2.246 0 100 4.492 2.246 2.246 0 000-4.492zM4.754 8a3.246 3.246 0 116.492 0 3.246 3.246 0 01-6.492 0z",
			clipRule: "evenodd",
		})
	);
};

Gear.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Gear.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var BrightnessHighFill = function BrightnessHighFill(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("circle", {
			cx: "8",
			cy: "8",
			r: "4",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M8 0a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2A.5.5 0 018 0zm0 13a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2A.5.5 0 018 13zm8-5a.5.5 0 01-.5.5h-2a.5.5 0 010-1h2a.5.5 0 01.5.5zM3 8a.5.5 0 01-.5.5h-2a.5.5 0 010-1h2A.5.5 0 013 8zm10.657-5.657a.5.5 0 010 .707l-1.414 1.415a.5.5 0 11-.707-.708l1.414-1.414a.5.5 0 01.707 0zm-9.193 9.193a.5.5 0 010 .707L3.05 13.657a.5.5 0 01-.707-.707l1.414-1.414a.5.5 0 01.707 0zm9.193 2.121a.5.5 0 01-.707 0l-1.414-1.414a.5.5 0 01.707-.707l1.414 1.414a.5.5 0 010 .707zM4.464 4.465a.5.5 0 01-.707 0L2.343 3.05a.5.5 0 01.707-.707l1.414 1.414a.5.5 0 010 .708z",
			clipRule: "evenodd",
		})
	);
};

BrightnessHighFill.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
BrightnessHighFill.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var BrightnessLowFill = function BrightnessLowFill(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("circle", {
			cx: "8",
			cy: "8",
			r: "4",
		}),
		React.createElement("circle", {
			cx: "8",
			cy: "2.5",
			r: ".5",
		}),
		React.createElement("circle", {
			cx: "8",
			cy: "13.5",
			r: ".5",
		}),
		React.createElement("circle", {
			cx: "13.5",
			cy: "8",
			r: ".5",
			transform: "rotate(90 13.5 8)",
		}),
		React.createElement("circle", {
			cx: "2.5",
			cy: "8",
			r: ".5",
			transform: "rotate(90 2.5 8)",
		}),
		React.createElement("circle", {
			cx: "11.889",
			cy: "4.111",
			r: ".5",
			transform: "rotate(45 11.89 4.11)",
		}),
		React.createElement("circle", {
			cx: "4.111",
			cy: "11.889",
			r: ".5",
			transform: "rotate(45 4.11 11.89)",
		}),
		React.createElement("circle", {
			cx: "11.889",
			cy: "11.889",
			r: ".5",
			transform: "rotate(135 11.89 11.889)",
		}),
		React.createElement("circle", {
			cx: "4.111",
			cy: "4.111",
			r: ".5",
			transform: "rotate(135 4.11 4.11)",
		})
	);
};

BrightnessLowFill.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
BrightnessLowFill.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var Unlock = function Unlock(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M9.655 8H2.333c-.264 0-.398.068-.471.121a.73.73 0 00-.224.296 1.626 1.626 0 00-.138.59V14c0 .342.076.531.14.635.064.106.151.18.256.237a1.122 1.122 0 00.436.127l.013.001h7.322c.264 0 .398-.068.471-.121a.73.73 0 00.224-.296 1.627 1.627 0 00.138-.59V9c0-.342-.076-.531-.14-.635a.658.658 0 00-.255-.237A1.122 1.122 0 009.655 8zm.012-1H2.333C.5 7 .5 9 .5 9v5c0 2 1.833 2 1.833 2h7.334c1.833 0 1.833-2 1.833-2V9c0-2-1.833-2-1.833-2zM8.5 4a3.5 3.5 0 117 0v3h-1V4a2.5 2.5 0 00-5 0v3h-1V4z",
			clipRule: "evenodd",
		})
	);
};

Unlock.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Unlock.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var Lock = function Lock(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M11.5 8h-7a1 1 0 00-1 1v5a1 1 0 001 1h7a1 1 0 001-1V9a1 1 0 00-1-1zm-7-1a2 2 0 00-2 2v5a2 2 0 002 2h7a2 2 0 002-2V9a2 2 0 00-2-2h-7zm0-3a3.5 3.5 0 117 0v3h-1V4a2.5 2.5 0 00-5 0v3h-1V4z",
			clipRule: "evenodd",
		})
	);
};

Lock.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Lock.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var Plug = function Plug(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			d: "M4 5h8v3a4 4 0 01-8 0V5z",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M6 1.5a.5.5 0 01.5.5v3a.5.5 0 01-1 0V2a.5.5 0 01.5-.5zm4 0a.5.5 0 01.5.5v3a.5.5 0 01-1 0V2a.5.5 0 01.5-.5zM7.115 13.651c.256-.511.385-1.408.385-2.651h1c0 1.257-.121 2.36-.49 3.099-.191.381-.47.707-.87.877-.401.17-.845.15-1.298-.002-.961-.32-1.534-.175-1.851.046-.33.23-.491.615-.491.98h-1c0-.635.278-1.353.918-1.8.653-.456 1.58-.561 2.74-.174.297.099.478.078.592.03.115-.05.244-.161.365-.405z",
			clipRule: "evenodd",
		})
	);
};

Plug.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Plug.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var PersonCheck = function PersonCheck(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M11 14s1 0 1-1-1-4-6-4-6 3-6 4 1 1 1 1h10zm-9.995-.944v-.002.002zM1.022 13h9.956a.274.274 0 00.014-.002l.008-.002c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664a1.05 1.05 0 00.022.004zm9.974.056v-.002.002zM6 7a2 2 0 100-4 2 2 0 000 4zm3-2a3 3 0 11-6 0 3 3 0 016 0zm6.854.146a.5.5 0 010 .708l-3 3a.5.5 0 01-.708 0l-1.5-1.5a.5.5 0 01.708-.708L12.5 7.793l2.646-2.647a.5.5 0 01.708 0z",
			clipRule: "evenodd",
		})
	);
};

PersonCheck.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
PersonCheck.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var XCircle = function XCircle(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z",
			clipRule: "evenodd",
		})
	);
};

XCircle.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
XCircle.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var BoxArrowUpRight = function BoxArrowUpRight(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M1.5 13A1.5 1.5 0 003 14.5h8a1.5 1.5 0 001.5-1.5V9a.5.5 0 00-1 0v4a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h4a.5.5 0 000-1H3A1.5 1.5 0 001.5 5v8zm7-11a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V2.5H9a.5.5 0 01-.5-.5z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M14.354 1.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z",
			clipRule: "evenodd",
		})
	);
};

BoxArrowUpRight.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
BoxArrowUpRight.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var ExclamationDiamond = function ExclamationDiamond(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 010-2.098L6.95.435zm1.4.7a.495.495 0 00-.7 0L1.134 7.65a.495.495 0 000 .7l6.516 6.516a.495.495 0 00.7 0l6.516-6.516a.495.495 0 000-.7L8.35 1.134z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			d: "M7.002 11a1 1 0 112 0 1 1 0 01-2 0zM7.1 4.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0L7.1 4.995z",
		})
	);
};

ExclamationDiamond.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
ExclamationDiamond.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var BoxArrowLeft = function BoxArrowLeft(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M4.354 11.354a.5.5 0 000-.708L1.707 8l2.647-2.646a.5.5 0 10-.708-.708l-3 3a.5.5 0 000 .708l3 3a.5.5 0 00.708 0z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M11.5 8a.5.5 0 00-.5-.5H2a.5.5 0 000 1h9a.5.5 0 00.5-.5z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M14 13.5a1.5 1.5 0 001.5-1.5V4A1.5 1.5 0 0014 2.5H7A1.5 1.5 0 005.5 4v1.5a.5.5 0 001 0V4a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v8a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-1.5a.5.5 0 00-1 0V12A1.5 1.5 0 007 13.5h7z",
			clipRule: "evenodd",
		})
	);
};

BoxArrowLeft.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
BoxArrowLeft.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var Cloud = function Cloud(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M4.887 7.2l-.964-.165A2.5 2.5 0 103.5 12h10a1.5 1.5 0 00.237-2.981L12.7 8.854l.216-1.028a4 4 0 10-7.843-1.587l-.185.96zm9.084.341a5 5 0 00-9.88-1.492A3.5 3.5 0 103.5 13h9.999a2.5 2.5 0 00.394-4.968c.033-.16.06-.324.077-.49z",
			clipRule: "evenodd",
		})
	);
};

Cloud.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Cloud.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var Archive = function Archive(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M2 5v7.5c0 .864.642 1.5 1.357 1.5h9.286c.715 0 1.357-.636 1.357-1.5V5h1v7.5c0 1.345-1.021 2.5-2.357 2.5H3.357C2.021 15 1 13.845 1 12.5V5h1z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M5.5 7.5A.5.5 0 016 7h4a.5.5 0 010 1H6a.5.5 0 01-.5-.5zM15 2H1v2h14V2zM1 1a1 1 0 00-1 1v2a1 1 0 001 1h14a1 1 0 001-1V2a1 1 0 00-1-1H1z",
			clipRule: "evenodd",
		})
	);
};

Archive.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Archive.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var Easel = function Easel(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M8 0a.5.5 0 0 1 .473.337L9.046 2H14a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1.85l1.323 3.837a.5.5 0 1 1-.946.326L11.092 11H8.5v3a.5.5 0 0 1-1 0v-3H4.908l-1.435 4.163a.5.5 0 1 1-.946-.326L3.85 11H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4.954L7.527.337A.5.5 0 0 1 8 0zM2 3v7h12V3H2z",
			clipRule: "evenodd",
		})
	);
};

Easel.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Easel.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var BarChartSteps = function BarChartSteps(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0zM2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1z",
			clipRule: "evenodd",
		})
	);
};

BarChartSteps.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
BarChartSteps.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var ClipboardCheck = function BarChartSteps(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z",
			clipRule: "evenodd",
		})
	);
};

ClipboardCheck.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
ClipboardCheck.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var House = function House(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M2 13.5V7h1v6.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5V7h1v6.5a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 13.5zm11-11V6l-2-2V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M7.293 1.5a1 1 0 011.414 0l6.647 6.646a.5.5 0 01-.708.708L8 2.207 1.354 8.854a.5.5 0 11-.708-.708L7.293 1.5z",
			clipRule: "evenodd",
		})
	);
};

House.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
House.defaultProps = {
	color: "currentColor",
	size: "1em",
};
var CardChecklist = function CardChecklist(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M14.5 3h-13a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5zm-13-1A1.5 1.5 0 000 3.5v9A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0014.5 2h-13z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M7 5.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zm-1.496-.854a.5.5 0 010 .708l-1.5 1.5a.5.5 0 01-.708 0l-.5-.5a.5.5 0 11.708-.708l.146.147 1.146-1.147a.5.5 0 01.708 0zM7 9.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zm-1.496-.854a.5.5 0 010 .708l-1.5 1.5a.5.5 0 01-.708 0l-.5-.5a.5.5 0 01.708-.708l.146.147 1.146-1.147a.5.5 0 01.708 0z",
			clipRule: "evenodd",
		})
	);
};

CardChecklist.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
CardChecklist.defaultProps = {
	color: "currentColor",
	size: "1em",
};
var Router = function Router(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M5.525 3.025a3.5 3.5 0 0 1 4.95 0 .5.5 0 1 0 .707-.707 4.5 4.5 0 0 0-6.364 0 .5.5 0 0 0 .707.707Z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M6.94 4.44a1.5 1.5 0 0 1 2.12 0 .5.5 0 0 0 .708-.708 2.5 2.5 0 0 0-3.536 0 .5.5 0 0 0 .707.707ZM2.5 11a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm4.5-.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2.5.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm1.5-.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M2.974 2.342a.5.5 0 1 0-.948.316L3.806 8H1.5A1.5 1.5 0 0 0 0 9.5v2A1.5 1.5 0 0 0 1.5 13H2a.5.5 0 0 0 .5.5h2A.5.5 0 0 0 5 13h6a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5h.5a1.5 1.5 0 0 0 1.5-1.5v-2A1.5 1.5 0 0 0 14.5 8h-2.306l1.78-5.342a.5.5 0 1 0-.948-.316L11.14 8H4.86L2.974 2.342ZM14.5 9a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h13Z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M8.5 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z",
			clipRule: "evenodd",
		})
	);
};

Router.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Router.defaultProps = {
	color: "currentColor",
	size: "1em",
};

var Building = function Building(props) {
	var color = props.color,
		size = props.size,
		rest = _objectWithoutProperties(props, ["color", "size"]);

	return React.createElement(
		"svg",
		_extends(
			{
				xmlns: "http://www.w3.org/2000/svg",
				viewBox: "0 0 16 16",
				width: size,
				height: size,
				fill: color,
			},
			rest
		),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M15.285.089A.5.5 0 0115.5.5v15a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V14h-1v1.5a.5.5 0 01-.5.5H1a.5.5 0 01-.5-.5v-6a.5.5 0 01.418-.493l5.582-.93V3.5a.5.5 0 01.324-.468l8-3a.5.5 0 01.46.057zM7.5 3.846V8.5a.5.5 0 01-.418.493l-5.582.93V15h8v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V15h2V1.222l-7 2.624z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			fillRule: "evenodd",
			d: "M6.5 15.5v-7h1v7h-1z",
			clipRule: "evenodd",
		}),
		React.createElement("path", {
			d: "M2.5 11h1v1h-1v-1zm2 0h1v1h-1v-1zm-2 2h1v1h-1v-1zm2 0h1v1h-1v-1zm6-10h1v1h-1V3zm2 0h1v1h-1V3zm-4 2h1v1h-1V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm-2 2h1v1h-1V7zm2 0h1v1h-1V7zm-4 0h1v1h-1V7zm0 2h1v1h-1V9zm2 0h1v1h-1V9zm2 0h1v1h-1V9zm-4 2h1v1h-1v-1zm2 0h1v1h-1v-1zm2 0h1v1h-1v-1z",
		})
	);
};

Building.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Building.defaultProps = {
	color: "currentColor",
	size: "1em",
};

exports.ExclamationSquare = ExclamationSquare;
exports.BoundingBoxCircles = BoundingBoxCircles;
exports.FolderCheck = FolderCheck;
exports.FolderFill = FolderFill;
exports.GraphUp = GraphUp;
exports.Alt = Alt;
exports.Gear = Gear;
exports.BrightnessHighFill = BrightnessHighFill;
exports.BrightnessLowFill = BrightnessLowFill;
exports.Unlock = Unlock;
exports.Lock = Lock;
exports.Plug = Plug;
exports.PersonCheck = PersonCheck;
exports.XCircle = XCircle;
exports.BoxArrowUpRight = BoxArrowUpRight;
exports.ExclamationDiamond = ExclamationDiamond;
exports.BoxArrowLeft = BoxArrowLeft;
exports.Cloud = Cloud;
exports.Archive = Archive;
exports.Easel = Easel;
exports.BarChartSteps = BarChartSteps;
exports.ClipboardCheck = ClipboardCheck;
exports.House = House;
exports.CardChecklist = CardChecklist;
exports.Router = Router;
exports.Building = Building;
