import React, { ReactElement, useContext, useState } from 'react';
import Close from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Slide from '@mui/material/Slide';
import Context from 'store/context';
import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Fab from '@mui/material/Fab';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import './network-ticket.css';
import { ContactSupportOutlined, SystemUpdateAlt } from '@mui/icons-material';
import { useHistory } from 'react-router';


interface ButtonPositionProps {
	x: number;
	y: number;
}
interface SupportMenuProps {
	title: string;
	icon: ReactElement;
	url: string;
}

export default function NewTicketDialog(props: ButtonPositionProps) {
	const { globalState } = useContext(Context);
	const history = useHistory();
	const [showDialog, setShowDialog] = useState(false);
	const [isLoadingConfData, setLoadingConfiData] = useState<boolean>(false);
	const [, setMakeDataCall] = useState<boolean>(false);
	const name = globalState?.userDetails?.name?.split(' ')[0];
	const initTitle = `${isLoadingConfData ? 'Getting ready...Please wait!' : `Hi ${name}, how can we support you today?`}`;
	const [title, ] = useState<string>(initTitle);

	const borders = {
		borderTopLeftRadius: '16px',
		borderTopRightRadius: '16px',
	};

	const navigateTo = (url: string) => {
		history.push(url);
		setShowDialog(false);
	};

	const SupportMenu = (menuProps: SupportMenuProps) => {
		return (<ListItem disablePadding>
				<ListItemButton onClick={() => navigateTo(menuProps.url)}>
					<ListItemIcon>{menuProps.icon}</ListItemIcon>
					<ListItemText primary={menuProps.title} />
				</ListItemButton>
			</ListItem>);
	};

	return (
		<div style={{ position: 'fixed', bottom: '20px', right: '20px', width: '20%', height: 'auto', ...borders }}>
			{showDialog ? (
				<Slide direction="left" in={showDialog} mountOnEnter unmountOnExit timeout={800} exit={!showDialog}>
					<div style={{ width: '100%', height: 'auto', ...borders }}>
						<Card elevation={10} style={borders}>
							<CardHeader
								className='ticket-card-header ticket-card-header-ready'
								title={title}
								action={<IconButton
											style={{ marginTop: '-10px', marginRight: '-8px', backgroundColor: 'white' }}
											size="small"
											onClick={() => {
												setLoadingConfiData(false);
												setShowDialog(false);
												setMakeDataCall(false);
											}}
										>
											<Close style={{ fontWeight: 'bolder', color: 'rgb(255, 69, 0)', fontSize: '1.25em' }} />
									</IconButton>
								}
							/>
							<List>
								<SupportMenu title='I.T Support?' icon={<ContactSupportOutlined />} url='/it-requests/support' />
								<SupportMenu title='Business Request?' icon={<SystemUpdateAlt />} url='/it-requests/business' />
							</List>
						</Card>
					</div>
				</Slide>
			) : (
				globalState &&
				globalState.isLoggedIn && (
					<Fab
						draggable
						className="new-ticket-btn"
						size='small'
						style={
							props.x === 15 && props.y === 10
								? { bottom: `${props.y}px`, right: `${props.x}px` }
								: { top: props.y, left: props.x, cursor: 'grab' }
						}
						onClick={() => {
							setShowDialog(true);
							setMakeDataCall(true);
						}}
					>
						<HeadsetMicIcon className="head-set-icon" />
					</Fab>
				)
			)}
		</div>
	);
}
