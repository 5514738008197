import { FolderCheck } from '../ReactBootstrapIcons';
import { LinkItem } from './types';

function getOadcLinks(): LinkItem[] {
	return [
		{ title: 'Home', path: '/oadc' },
		{
			title: 'Operations',
			items: [{ label: 'Data Centre Tickets', icon: FolderCheck, path: '/dc-tickets' }],
		},
	];
}
export default getOadcLinks;
