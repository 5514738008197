import React, { useState, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { PersonCheck, XCircle, ExclamationDiamond, BoxArrowLeft } from './ReactBootstrapIcons';
import { BoxArrowUpRight } from './ReactBootstrapIcons';
import { fetchJsonFromApiJsonResponse, postDataToApiJsonResponse } from '../common/FetchFunctions';
import { Bars, ThreeDots } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.min.css';
import { useToasts } from 'react-toast-notifications';
import { sum } from 'lodash';
import './netsuit-shared-accounts-modal.css';

function assignNetSuiteUser(
	account,
	globalState,
	globalDispatch,
	addToast,
	setAwaitingNetsuiteResponse,
	setShowNetsuiteSharedAccountsModal,
	setNetsuiteSharedAccounts
) {
	postDataToApiJsonResponse(`changeNetsuiteShared0365User`, {
		'endtype': 'assocateNSwithO365Acct',
		'NSemployeeID': account.internal_id,
		'O365EmplEmail': globalState.userDetails.email,
		'_email_': account.email,
	})
		.then(_account_info => {
			if (_account_info.response) {
				globalDispatch({
					type: 'SETUSER',
					userDetails: {
						name: globalState.userDetails.name,
						email: globalState.userDetails.email,
						netsuite_id: account.internal_id,
						isShiftWorker: account.isShiftWorker ? true : false,
						using_shared_netsuite_account: true,
						shared_netsuite_account: account.email,
						jobTitle: globalState.userDetails.jobTitle,
					},
				});
				addToast(`You are now using ${account.email}'s shared account on Netsuite.`, {
					appearance: 'success',
					autoDismiss: false,
				});
				window.open('/netsuite', '_blank');
			} else {
				addToast(`Error occured during account assignment`, {
					appearance: 'warning',
					autoDismiss: false,
				});
			}
			fetchJsonFromApiJsonResponse(`getNetsuiteSharedAccounts`)
				.then(_netsuiteSharedAccounts => {
					if (_netsuiteSharedAccounts.netsuiteSharedAccounts) {
						setNetsuiteSharedAccounts(_netsuiteSharedAccounts.netsuiteSharedAccounts);
					} else {
						// console.log(_tickets.msg)
					}
				})
				.catch(error => {
					console.log(error.message);
				});
			setAwaitingNetsuiteResponse(false);
			setShowNetsuiteSharedAccountsModal(false);
		})
		.catch(error => {
			console.log(error.message);
			addToast(`Error occured during account assignment`, {
				appearance: 'warning',
				autoDismiss: false,
			});
			setAwaitingNetsuiteResponse(false);
			setShowNetsuiteSharedAccountsModal(false);
		});
}

function NetSuiteSharedAccountInUseModal(props) {
	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			style={{
				fontFamily: 'Montserrat-SemiBold',
				fontSize: props.globalState.fontSize,
				color: '#000000',
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title
					id="contained-modal-title-vcenter"
					style={{
						fontFamily: 'Montserrat-SemiBold',
						fontSize: props.globalState.fontSize * 1.2,
						color: '#20358c',
					}}
				>
					NetSuite Shared Account In Use!
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div
					style={{
						backgroundColor: '#e9ecef',
						fontFamily: 'Montserrat-Regular',
						fontSize: props.globalState.fontSize * 1.1,
						// color: "#20358c",
						padding: '0.5rem',
						marginBottom: '1rem',
					}}
				>
					<div>
						<strong>'{props.account.title}'&nbsp;</strong>
						account is currently in use.
					</div>
					<div>
						Please note that if you choose to proceed, it will result in
						<strong>&nbsp;{props.account.last_logged_user}&nbsp;</strong>
						being logged out on NetSuite.
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					size="small"
					variant="warning"
					style={{
						margin: 'auto',
						fontFamily: 'Montserrat-Regular',
						fontSize: props.globalState.fontSize,
						textAlign: 'left',
						color: '#000000',
					}}
					// className="TopologyButtons"
					onClick={() => props.onHide()}
					disabled={props.awaitingNetsuiteResponse ? true : false}
					startIcon={<BoxArrowLeft />}
				>
					&nbsp;&nbsp;Back
				</Button>
				<Button
					size="sm"
					variant="success"
					style={{
						margin: 'auto',
						fontFamily: 'Montserrat-Regular',
						fontSize: props.globalState.fontSize,
						textAlign: 'left',
						color: '#000000',
					}}
					className="TopologyButtons"
					onClick={() => {
						props.setAwaitingNetsuiteResponse(true);
						assignNetSuiteUser(
							props.account,
							props.globalState,
							props.globalDispatch,
							props.addToast,
							props.setAwaitingNetsuiteResponse,
							props.setShowNetsuiteSharedAccountsModal,
							props.setNetsuiteSharedAccounts
						);
						props.onHide();
					}}
					disabled={props.awaitingNetsuiteResponse ? true : false}
				>
					Proceed&nbsp;&nbsp;
					<PersonCheck />
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

const AggregatedNetsuiteSharedAccounts = props => {
	const { inUse = {}, ...countMap } = props.netsuiteSharedAccountsAggregate ?? {};

	if (!props.canViewAllAccounts && props.usersAggregate) {
		//remove the aggregate for other (accounts that don't have a pool)
		delete props.usersAggregate.other;
	}

	let totalUsers = 0;

	return (
		<>
			{!props.usersAggregate && (
				<div>
					<ThreeDots
						className="mt-3"
						type="Bars"
						color="#20358c"
						height={20}
						width={20}
						style={{
							justifyContent: 'center',
							display: 'flex',
						}}
					/>
				</div>
			)}

			<Table
				striped
				borderless
				hover
				responsive
				variant={props.globalState.theme}
				size="sm"
				style={{
					fontFamily: 'Montserrat-Regular',
					fontSize: props.globalState.fontSize,
					textAlign: 'left',
					color: '#000000',
				}}
			>
				<thead>
					<tr>
						<th>#</th>
						<th>Role</th>
						<th>In Use</th>
						<th>Free</th>
						<th>Total</th>
						<th>Users</th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(countMap)
						.sort()
						.map((role, i) => {
							totalUsers += props.usersAggregate?.[role] ?? 0;
							return (
								<tr key={role} className={props.globalState.theme === 'dark' ? 'darkmoderow' : 'lightmoderow2'}>
									<td className="px-2">{i + 1}</td>
									<td>{role}</td>
									<td>{inUse[role]}</td>
									<td>{countMap[role] - inUse[role]}</td>
									<td>{countMap[role]}</td>
									<td>{props.usersAggregate?.[role] || '--'}</td>
								</tr>
							);
						})}
				</tbody>
				<tfoot>
					<tr>
						<td></td>
						<td>Total</td>
						<td></td>
						<td></td>
						<td>{sum(Object.values(countMap ?? {}))}</td>
						<td>{totalUsers}</td>
					</tr>
				</tfoot>
			</Table>
			<div className="w-100 my-2"></div>
		</>
	);
};

export default function NetsuiteSharedAccountsModal(props) {
	const [awaitingNetsuiteResponse, setAwaitingNetsuiteResponse] = useState(false);
	const [showNetSuiteSharedAccountInUse, setShowNetSuiteSharedAccountInUse] = useState(false);
	const [selectedNetSuiteSharedAccountInUse, setSelectedNetSuiteSharedAccountInUse] = useState({});
	const [viewAggregate, setViewAggregate] = useState(false);

	const { addToast } = useToasts();

	useEffect(() => {
		let intervalID = null;
		intervalID = setInterval(function () {
			clearInterval(intervalID);
			props.setLoadingNetSuiteSharedAccounts(false);
		}, 7000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [usersAggregate, setUsersAggregate] = useState({});

	const canViewAllAccounts =
		props.globalState.userDetails.jobTitle.includes('IT & Systems Engineer') ||
		props.globalState.userDetails.jobTitle.includes('Director, Business Support');

	const showAccount = (account, account_index) => {
		let accountNameAppend = '';

		const NOC_ENGINEER = 'nocengineer';
		const TAC_ENGINEER = 'tacengineer';

		if (account.email.startsWith(NOC_ENGINEER)) {
			accountNameAppend = account.email.split('@')[0].replace(NOC_ENGINEER, '');
		} else if (account.email.startsWith(TAC_ENGINEER)) {
			accountNameAppend = account.email.split('@')[0].replace(TAC_ENGINEER, '');
		}
		const accountInUse = account.status === 'In Use';

		//disable ability for another user to select network admin or engineering account in use
		const disableAbleAccounts = ['Engineering', 'Network Administration'];
		const disableSelectAccount =
			awaitingNetsuiteResponse || (accountInUse && disableAbleAccounts.includes(account.role)) || !props.canRequestSharedAccount;

		return (
			<tr key={account.email} className={props.globalState.theme === 'dark' ? 'darkmoderow' : 'lightmoderow2'}>
				<td>{account_index + 1}.</td>
				<td>
					{account.role} {accountNameAppend}
				</td>
				<td>{account.last_logged_user}</td>
				<td>{account.latest_activity}</td>
				<td>{account.status}</td>
				<td>
					<Button
						size="small"
						variant="contained"
						color={awaitingNetsuiteResponse || accountInUse ? 'warning' : 'success'}
						className="TopologyButtons"
						style={{ fontSize: 0.9 * props.globalState.fontSize }}
						disabled={disableSelectAccount}
						onClick={() => {
							setAwaitingNetsuiteResponse(true);
							if (!accountInUse || account.last_logged_user === props.globalState.userDetails.email) {
								assignNetSuiteUser(
									account,
									props.globalState,
									props.globalDispatch,
									addToast,
									setAwaitingNetsuiteResponse,
									props.setShowNetsuiteSharedAccountsModal,
									props.setNetsuiteSharedAccounts
								);
							} else {
								setAwaitingNetsuiteResponse(false);
								props.onHide();
								setSelectedNetSuiteSharedAccountInUse(account);
								setShowNetSuiteSharedAccountInUse(true);
							}
						}}
					>
						{awaitingNetsuiteResponse ? (
							<div style={{ display: 'inline' }}>
								Updating&nbsp;&nbsp;
								<ThreeDots type="ThreeDots" color="#20358c" height={15} width={15} style={{ float: 'right' }} />
							</div>
						) : (
							<>
								Select Account&nbsp;&nbsp;
								<PersonCheck />
							</>
						)}
					</Button>
				</td>
			</tr>
		);
	};

	const [uniqueSharedAccounts, aggregate] = useMemo(() => {
		const accounts = [];
		const emails = [];

		const aggregate = { inUse: {} };

		props.netsuiteSharedAccounts.forEach((account, i) => {
			const { email, role, status } = account;
			if (!emails.includes(email)) {
				accounts.push(showAccount(account, i));
				emails.push(email);

				//update aggregate
				if (!aggregate[role]) aggregate[role] = 0;
				aggregate[role]++;

				if (!aggregate.inUse[role]) aggregate.inUse[role] = 0;
				if (status === 'In Use') aggregate.inUse[role]++;
			}
		});

		return [accounts, aggregate];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.netsuiteSharedAccounts]);

	useEffect(() => {
		if (!viewAggregate || (usersAggregate && Object.keys(usersAggregate).length > 0)) return;
		fetchJsonFromApiJsonResponse('getSharedAccountsAggregation')
			.then(({ data, success, error }) => {
				if (success) {
					setUsersAggregate(data);
				} else {
					addToast(error ?? 'Error getting shared accounts aggregate', {
						appearance: 'danger',
						autoDismiss: false,
					});
				}
			})
			.catch(error => {
				addToast(`Error getting shared accounts aggregate`, {
					appearance: 'danger',
					autoDismiss: false,
				});
				console.log('error', error);
			});
	}, [viewAggregate, usersAggregate, addToast]);

	const NetsuiteSharedAccounts = () => {
		return (
			<Table
				striped
				borderless
				hover
				responsive
				variant={props.globalState.theme}
				size="sm"
				style={{
					fontFamily: 'Montserrat-Regular',
					fontSize: props.globalState.fontSize,
					textAlign: 'left',
					color: '#000000',
				}}
			>
				<thead>
					<tr>
						<th></th>
						<th>Account Name</th>
						<th>Last Logged User</th>
						<th>Latest Activity (UTC)</th>
						<th>Status</th>
						<th></th>
					</tr>
				</thead>
				<tbody>{uniqueSharedAccounts}</tbody>
			</Table>
		);
	};

	const onHide = () => {
		props.onHide();
		setViewAggregate(false);
	};

	const [openSharedAccountModal, setSharedAccountsModal] = useState(props.show);
	const handleCloseSharedAccountsModal = () => {
		props.setShowNetSuiteSharedAccountsOptions(false);
		props.setShowNetsuiteSharedAccountsModal(false);
	};

	return (
		<>
			<NetSuiteSharedAccountInUseModal
				show={showNetSuiteSharedAccountInUse}
				account={selectedNetSuiteSharedAccountInUse}
				addToast={addToast}
				globalState={props.globalState}
				globalDispatch={props.globalDispatch}
				awaitingNetsuiteResponse={awaitingNetsuiteResponse}
				setAwaitingNetsuiteResponse={setAwaitingNetsuiteResponse}
				setShowNetsuiteSharedAccountsModal={props.setShowNetsuiteSharedAccountsModal}
				setNetsuiteSharedAccounts={props.setNetsuiteSharedAccounts}
				onHide={() => {
					setShowNetSuiteSharedAccountInUse(false);
					props.setShowNetsuiteSharedAccountsModal(true);
				}}
			/>

			{/*<Dialog
					fullWidth
					maxWidth="md"
					open={props.show}>
						<div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
							<div style={{width: '100%', height: '10%'}}>
								<AppBar sx={{ position: 'relative'}} size='small'>
									<Toolbar>
										<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
											<div style={{width: '90%', display: 'flex', flexDirection: 'row'}}>
												<div style={{width: '60%'}}>
													<Typography variant="h6" component="div"> Login to NetSuite using a shared account.</Typography>
												</div>
												<div style={{width: '40%', display: 'flex', flexDirection: 'row'}}>
													
													<Button
														size="small"
														variant="contained"
														color={awaitingNetsuiteResponse ? 'warning' : 'success'}
														style={{
															height: '100%',
															marginRight: '2px'
														}}
														disabled={
															awaitingNetsuiteResponse || viewAggregate || !props.canRequestSharedAccount || props.showNetSuiteSharedAccountsOptions
																? true
																: false
														}
														onClick={() => {
															setAwaitingNetsuiteResponse(true);
															postDataToApiJsonResponse(`requestNetsuiteSharedAccount`, {
																'O365EmplEmail': props.globalState.userDetails.email,
															})
																.then(_netsuiteSharedAccReq => {
																	console.log(_netsuiteSharedAccReq);
																	setAwaitingNetsuiteResponse(false);
																	if (_netsuiteSharedAccReq.account_found) {
																		props.onHide();
																		addToast(`You are now using ${_netsuiteSharedAccReq.selected_account.email}'s shared account on Netsuite.`, {
																			appearance: 'success',
																			autoDismiss: false,
																		});
																		
																		props.globalDispatch({
																			type: 'SETUSER',
																			userDetails: {
																				name: props.globalState.userDetails.name,
																				email: props.globalState.userDetails.email,
																				netsuite_id: _netsuiteSharedAccReq.selected_account.internal_id,
																				isShiftWorker: props.globalState.userDetails.isShiftWorker,
																				using_shared_netsuite_account: true,
																				shared_netsuite_account: _netsuiteSharedAccReq.selected_account.email,
																				jobTitle: props.globalState.userDetails.jobTitle,
																			},
																		});
																		window.open('/netsuite', '_blank');
																	} else {
																		if (_netsuiteSharedAccReq.netsuiteSharedAccounts) {
																			addToast(
																				`There are no available shared accounts at the moment. Please check again in ${_netsuiteSharedAccReq.wait_time} minutes.`,
																				{
																					appearance: 'warning',
																					autoDismiss: false,
																				}
																			);
																			props.setNetsuiteSharedAccounts(_netsuiteSharedAccReq.netsuiteSharedAccounts);
																			props.setShowNetSuiteSharedAccountsOptions(true);
																		} else {
																			addToast(`Error occured. Please contact system administrator.`, {
																				appearance: 'error',
																				autoDismiss: false,
																			});
																		}
																	}
																})
																.catch(error => {
																	console.log(error.message);
																});
														}}
													>
														{awaitingNetsuiteResponse ? (
															<ThreeDots color="#FFFFFF" height={30} width={40} style={{display: 'inline'}} />
														) : props.showNetSuiteSharedAccountsOptions ? (
															<>
																All accounts in use&nbsp;&nbsp;
																<PersonCheck />
															</>
														) : (
															<>
																Request for Shared Account&nbsp;&nbsp;
																<PersonCheck />
															</>
														)}
													</Button>
													<Button
														size="small"
														variant="contained"
														color={viewAggregate ? 'warning' : 'success'}
														style={{
															height: '100%',
															marginLeft: '2px'
														}}
														disabled={!props.netsuiteSharedAccounts || props?.netsuiteSharedAccounts?.length === undefined}
														onClick={() => setViewAggregate(!viewAggregate)}
													>
														{viewAggregate ? 'Back' : 'View Aggregate'}
													</Button>
												</div>
											</div>
											<div style={{width: '10%', display: 'flex', justifyContent: 'end'}}>
											<Button
									size="medium"
									variant="contained"
									color="warning"
									onClick={() => {
										props.onHide();
									}}
									disabled={awaitingNetsuiteResponse ? true : false}
									endIcon={<XCircle />}
								>
									Close
								</Button>
											</div>
										</div>
									</Toolbar>
								</AppBar>
							</div>
							<div style={{width: '100%', height: '90%'}}>
								<Card style={{marginTop: '-12px'}}>
									<CardContent style={{overflow: 'auto', height: '450px', display: 'flex', flexDirection: 'column'}}>
										<div style={{width: '100%', height: '10%', display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgba(255,195,2, 0.2)'}}>
											<ExclamationDiamond size={20} style={{color: '#E4A11B'}} />
											<p style={{fontSize: '.8em',  fontWeight: 'bold'}}>
												Please ensure that you have installed WIOCC Hub's browser extension before requesting for a shared account below. Click
												<Button
													size="small"
													onClick={() => {
														props.onHide();
														props.setShowExtensionModal(true);
													}}
													variant="text"
												>
													&nbsp;here&nbsp;
													<BoxArrowUpRight />
													&nbsp;
												</Button>
												to get the browser extensions (and installation instructions).
											</p>
										</div>
										<div style={{width: '100%', height: '90%', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
											{props.showNetSuiteSharedAccountsOptions ||
											canViewAllAccounts ||
											props.globalState.userDetails.jobTitle.includes('NOC') ||
											props.globalState.userDetails.jobTitle.includes('TAC Engineer') ||
											props.globalState.userDetails.jobTitle.includes('Service Manager') ? (
												<div style={{width: '100%', height: '100%'}}>
													{props.loadingNetSuiteSharedAccounts ? (
														<div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
															<Bars color="#20358c" height={50} width={60} />
														</div>
													) : viewAggregate ? (
														<AggregatedNetsuiteSharedAccounts
															globalState={props.globalState}
															usersAggregate={usersAggregate}
															netsuiteSharedAccountsAggregate={aggregate}
															canViewAllAccounts={canViewAllAccounts}
														/>
													) : (
														<NetsuiteSharedAccounts />
													)}
												</div>
											) : viewAggregate ? (
												<AggregatedNetsuiteSharedAccounts
													globalState={props.globalState}
													usersAggregate={usersAggregate}
													netsuiteSharedAccounts={props.netsuiteSharedAccounts}
													netsuiteSharedAccountsAggregate={aggregate}
													canViewAllAccounts={canViewAllAccounts}
												/>
											) : awaitingNetsuiteResponse || !props?.netsuiteSharedAccounts?.length > 0 ? (
												<div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', border: '2px solid green'}}>
													<Bars color="#20358c" height={40} width={40} style={{display: 'flex', textAlign: 'center'}} />
												</div>
											) : (
												<></>
											)}
										</div>
									</CardContent>
								</Card>
							</div>
						</div>
				</Dialog>*/}

			<Modal
				show={props.show}
				onHide={onHide}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				style={{
					fontFamily: 'Montserrat-SemiBold',
					fontSize: props.globalState.fontSize,
					color: '#000000',
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title
						id="contained-modal-title-vcenter"
						style={{
							fontFamily: 'Montserrat-SemiBold',
							fontSize: props.globalState.fontSize * 1.2,
							color: '#20358c',
						}}
					>
						Login to NetSuite using a shared account.
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Box
						style={{
							backgroundColor: '#e9ecef',
							fontFamily: 'Montserrat-Regular',
							fontSize: '0.8rem',
							color: '#20358c',
							padding: '0.5rem',
							marginBottom: '1rem',
						}}
					>
						<Box sx={{ display: 'inline-block', mr: 1 }}>
							<ExclamationDiamond />
						</Box>
						Please ensure that you have installed WIOCC Hub's browser extension before requesting for a shared account below. Click
						<Button
							size="small"
							onClick={() => {
								props.onHide();
								props.setShowExtensionModal(true);
							}}
							variant="text"
						>
							&nbsp;here&nbsp;
							<BoxArrowUpRight />
							&nbsp;
						</Button>
						to get the browser extensions(and installation instructions).
					</Box>
					<div
						className="container-fluid"
						style={{
							width: '98%',
							overflowY: 'auto',
							maxHeight: '50vh',
							textAlign: 'center',
						}}
					>
						<Button
							size="small"
							variant="contained"
							color={awaitingNetsuiteResponse ? 'warning' : 'success'}
							className="TopologyButtons"
							style={{
								fontSize: 0.9 * props.globalState.fontSize,
							}}
							disabled={
								awaitingNetsuiteResponse || viewAggregate || !props.canRequestSharedAccount || props.showNetSuiteSharedAccountsOptions
									? true
									: false
							}
							onClick={() => {
								setAwaitingNetsuiteResponse(true);
								postDataToApiJsonResponse(`requestNetsuiteSharedAccount`, {
									'O365EmplEmail': props.globalState.userDetails.email,
								})
									.then(_netsuiteSharedAccReq => {
										console.log(_netsuiteSharedAccReq);
										setAwaitingNetsuiteResponse(false);
										if (_netsuiteSharedAccReq.account_found) {
											props.onHide();
											addToast(`You are now using ${_netsuiteSharedAccReq.selected_account.email}'s shared account on Netsuite.`, {
												appearance: 'success',
												autoDismiss: false,
											});

											props.globalDispatch({
												type: 'SETUSER',
												userDetails: {
													name: props.globalState.userDetails.name,
													email: props.globalState.userDetails.email,
													netsuite_id: _netsuiteSharedAccReq.selected_account.internal_id,
													isShiftWorker: props.globalState.userDetails.isShiftWorker,
													using_shared_netsuite_account: true,
													shared_netsuite_account: _netsuiteSharedAccReq.selected_account.email,
													jobTitle: props.globalState.userDetails.jobTitle,
												},
											});
											window.open('/netsuite', '_blank');
										} else {
											if (_netsuiteSharedAccReq.netsuiteSharedAccounts) {
												addToast(
													`There are no available shared accounts at the moment. Please check again in ${_netsuiteSharedAccReq.wait_time} minutes.`,
													{
														appearance: 'warning',
														autoDismiss: false,
													}
												);
												props.setNetsuiteSharedAccounts(_netsuiteSharedAccReq.netsuiteSharedAccounts);
												props.setShowNetSuiteSharedAccountsOptions(true);
											} else {
												addToast(`Error occured. Please contact system administrator.`, {
													appearance: 'error',
													autoDismiss: false,
												});
											}
										}
									})
									.catch(error => {
										console.log(error.message);
									});
							}}
						>
							{awaitingNetsuiteResponse ? (
								<div style={{ display: 'inline' }}>
									Updating&nbsp;&nbsp;
									<ThreeDots type="ThreeDots" color="#20358c" height={15} width={15} style={{ float: 'right' }} />
								</div>
							) : props.showNetSuiteSharedAccountsOptions ? (
								<>
									All accounts in use&nbsp;&nbsp;
									<PersonCheck />
								</>
							) : (
								<>
									Request for Shared Account&nbsp;&nbsp;
									<PersonCheck />
								</>
							)}
						</Button>

						<Button
							size="small"
							variant="contained"
							color={awaitingNetsuiteResponse || viewAggregate ? 'warning' : 'success'}
							className="TopologyButtons mx-2"
							style={{
								fontSize: 0.9 * props.globalState.fontSize,
							}}
							disabled={!props.netsuiteSharedAccounts || props?.netsuiteSharedAccounts?.length === undefined}
							onClick={() => setViewAggregate(!viewAggregate)}
						>
							{viewAggregate ? 'Back' : 'View Aggregate'}
						</Button>

						{props.showNetSuiteSharedAccountsOptions ||
						canViewAllAccounts ||
						props.globalState.userDetails.jobTitle.includes('NOC') ||
						props.globalState.userDetails.jobTitle.includes('TAC Engineer') ||
						props.globalState.userDetails.jobTitle.includes('Service Manager') ? (
							<>
								<hr />
								{props.loadingNetSuiteSharedAccounts ? (
									<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
										<Bars color="#20358c" height={40} width={40} />
									</div>
								) : viewAggregate ? (
									<AggregatedNetsuiteSharedAccounts
										globalState={props.globalState}
										usersAggregate={usersAggregate}
										netsuiteSharedAccountsAggregate={aggregate}
										canViewAllAccounts={canViewAllAccounts}
									/>
								) : (
									<NetsuiteSharedAccounts />
								)}
							</>
						) : viewAggregate ? (
							<AggregatedNetsuiteSharedAccounts
								globalState={props.globalState}
								usersAggregate={usersAggregate}
								netsuiteSharedAccounts={props.netsuiteSharedAccounts}
								netsuiteSharedAccountsAggregate={aggregate}
								canViewAllAccounts={canViewAllAccounts}
							/>
						) : awaitingNetsuiteResponse || !props?.netsuiteSharedAccounts?.length > 0 ? (
							<Bars
								className="mt-3"
								type="Bars"
								color="#20358c"
								height={40}
								width={40}
								style={{
									justifyContent: 'center',
									display: 'flex',
								}}
							/>
						) : (
							<></>
						)}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						size="small"
						variant="contained"
						color="warning"
						style={{
							margin: 'auto',
							textAlign: 'left',
						}}
						onClick={() => {
							props.onHide();
						}}
						disabled={awaitingNetsuiteResponse ? true : false}
						endIcon={<XCircle />}
					>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
