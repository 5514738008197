import { lazy } from "react";
import type { RouteObject } from "./interfaces";
import ClientNetworkTicketView from "operations/ticketing/network-tickets/single-views/network-client-ticket";

const WIOCCIPCORE = lazy(() => import("components/topology/WIOCCIPCorePage"));
const AlarmsPage = lazy(() => import("operations/alarms/AlarmsPage"));
const TejasTopologyPage = lazy(
  () => import("components/topology/TejasTopologyPage")
);
const SupplierNetworkTicketView = lazy(
  () =>
    import(
      "operations/ticketing/network-tickets/single-views/network-supplier-ticket"
    )
);
const NetworkTickets = lazy(
  () => import("operations/ticketing/network-tickets")
);
const DCTickets = lazy(() => import("oadc/dc-tickets"));
// const SingleDCTicket = lazy(() => import('oadc/dc-tickets/single-ticket'));
const TicketsPage = lazy(() => import("operations/ticketing/TicketsPage"));
const NetSuiteNetworkTicketPage = lazy(
  () => import("operations/ticketing/NetSuiteNetworkTicketPage")
);
const NetSuiteSupportTicketPage = lazy(
  () => import("operations/ticketing/NetSuiteSupportTicketPage")
);

const InsightsPage = lazy(() => import("operations/insights/InsightsPage"));
const ChangeManagement = lazy(
  () => import("operations/insights/subtabs/ChangeManagementConfigs")
);
const ConfigOnePage = lazy(
  () => import("components/configurator/ConfigStartPage")
);
const SqsmReport = lazy(() => import("operations/ticketing/support/sqsm"));
const MasterArchitecturePage = lazy(
  () => import("components/master_architecture/MasterArchitecturePage")
);
const Visitors = lazy(() => import("operations/visitors-log/index"));

const operationsRoutes: RouteObject[] = [
  { path: "/network-tickets", element: NetworkTickets },
  // { path: '/dc-tickets/:ticketId', element: SingleDCTicket },
  { path: "/dc-tickets", element: DCTickets },
  // { path: '/contacts', element: lazy(() => import('../components/contacts/Contacts')) },

  { path: "/alarms", element: AlarmsPage },
  { path: "/topology", element: TejasTopologyPage },
  { path: "/wioccipcore", element: WIOCCIPCORE },

  { path: "/masterarchitecture", element: MasterArchitecturePage },
  { path: "/configurator", element: ConfigOnePage },

  /* TICKETS */
  { path: "/tickets/reports/supplier", element: SqsmReport },
  { path: "/tickets", highlighted_tab: "my_tickets", element: TicketsPage },
  { path: "/network_ticket/:ticket_id", element: NetSuiteNetworkTicketPage },
  { path: "/supplier_ticket/:ticket_id", element: SupplierNetworkTicketView },
  { path: "/client_ticket/:ticket_id", element: ClientNetworkTicketView },
  { path: "/support_ticket/:ticket_id", element: NetSuiteSupportTicketPage },
  { path: "/insights", element: InsightsPage },
  { path: "/network-configuration", element: ChangeManagement },
  { path: "/visitors", element: Visitors },
];

export default operationsRoutes;
