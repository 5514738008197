import { type Dispatch } from "react";

export interface AnyObject {
  [key: string | number]: string | number;
}

export interface UserDetails {
  name: string | null;
  email: string | null;
  netsuite_id: number;
  isShiftWorker: boolean | null;
  using_shared_netsuite_account: boolean | null;
  is_service_delivery: boolean | null;
  shared_netsuite_account: boolean | null;
  jobTitle: string | null;
  PRTG_GRAPHS_API: string;
  PRTG_NETSUITE_CREDENTIALS: string;
  is_2africa_team?: boolean;
}

export enum EmailType {
  WIOCC = "wiocc.net",
  OAMS = "openaccessmetro.net",
  OADC = "openaccessdc.net",
}

export enum ProfileType {
  CONNECTIVITY = "connectivity",
  OADC = "oadc",
  OAMS = "oams",
}

export interface GlobalState {
  isLoggedIn: boolean;
  userDetails: UserDetails;
  priorityDict: { port: AnyObject };
  // priorityDict: { port: Record<string, (string | number)[]> };
  theme: string;
  filterDetails: {
    filterState: AnyObject;
    filterLabels: AnyObject;
    availableFilters: string[];
  };
  profile: ProfileType;
  profileChangeTriggered?: boolean;
  alreadyNavigatedToProfile?: boolean;
  fontSize: number;
  window_height: number;
  window_width: number;
}

export const getEmptyGlobalState = (): GlobalState => {
  return {
    isLoggedIn: false,
    userDetails: {
      name: null,
      email: null,
      netsuite_id: -1,
      isShiftWorker: null,
      is_service_delivery: false,
      using_shared_netsuite_account: null,
      shared_netsuite_account: null,
      jobTitle: null,
      PRTG_GRAPHS_API: "",
      PRTG_NETSUITE_CREDENTIALS: "",
    },
    priorityDict: { port: {} },
    theme: "light",
    filterDetails: {
      filterState: {},
      filterLabels: {},
      availableFilters: [],
    },
    profile: ProfileType.CONNECTIVITY,
    fontSize: 13,
    window_height: window.outerHeight,
    window_width: window.outerWidth,
  };
};

export interface GlobalStateValues {
  globalState: GlobalState;
  globalDispatch: Dispatch<Record<string, any>>;
}
